.admin-course-layout {
  .package_menu__title {display: none;}
  .list_header_text {display: none;}
  &.draweropen {
    .package_menu__title {display: flex;}
    .list_header_text {display: flex}
    .list-item-nested {
      border-radius: 16px;
      &.active {
        background: #006847;
        color: white;
        box-shadow: 1px 2px 10px #0000008c;
        .MuiListItemIcon-root {
          color: white
        }
      }
    }
  }
  .MuiDrawer-docked { transition: none!important; }

  &.false {
    .main__logo {
      img { max-height: 15px!important }
    }
    .MuiList-root {
      margin-left: 0px;
      margin-right: 0px;
      .list-item-nested {
        border-radius: 16px;
        &.active {
          background: #006847;
          color: white;
          box-shadow: 1px 2px 10px #0000008c;
          .MuiListItemIcon-root {color: white}
        }
      }
    }
    .course-list{
      padding-top: 24px;
      .active {
        background: #006848;
        .MuiListItemIcon-root{
          color: white;
        }
      }
    }
    main {
      width: 100%;
      max-width: calc(100% - 65px);
      header {
        width: calc(100% - 65px);
      }
    }
  }
}
