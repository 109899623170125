.arrow {
  overflow: hidden;
  position: absolute;
  width: 1em;
  height: 0.71em /* = width / sqrt(2) = (length of the hypotenuse) */
;
  box-sizing: border-box;
  // top: 0;
  // left: calc(100% - 356px);
  margin-top: -0.71em;
  margin-left: 4px;
  margin-right: 4px;
  // @media (min-width: $breakpoint-lg) {
  //   left: 35%;
  // }
}

.arrow::before {
  content: '';
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
  //boxShadow: theme.shadows[1]
  background-color: white;
  transform: rotate(45deg);
  transform-origin: 0 100%;
}
