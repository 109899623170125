/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.course_overview_quickview {
  h2 {
    font-size: 24px;
  }
  .MuiDialog-container {
    .MuiBox-root {
      margin-bottom: 10px;
    }
    .course_overview_quickview_media {
      max-width: calc(100% + 40px);
      margin-bottom: 20px;
      position: relative;
      img {
        max-width: calc(100% + 40px);
        width: 100%;
      }
      .course_overview_quickview_chip {
        position: absolute;
        left: 0px;
        top: 10px;
      }
      .course_overview_quickview_media_actions {
        bottom: 0px;
        left: 12px;
        position: absolute;
        .MuiButtonBase-root:not(.favourite_icon) {
          margin-right: 10px;
          .MuiSvgIcon-root {
            margin-right: 10px;
          }
        }
        .MuiButtonBase-root.favourite_icon {
          background: #fff;
        }
      }
      .course_overview_quickview_accreditations {
        position: absolute;
        bottom: 0px;
        left: inherit;
        right: 10px;
        display: flex;
        .course_overview_card_accreditations {
          display: flex;
        }
        .MuiAvatar-root {
          margin-left: 2.5px;
          margin-right: 2.5px;
          width: 25px;
          height: 25px;
          font-size: 14px;
          &.chip {
            background: #fff;
            padding: 0px 5px;
            color: #01689b;
            text-transform: uppercase;
            border: 1px solid #01689b;
            font-weight: bold;
            border-radius: 4px;
            font-size: 12px;
            width: 50px;
          }
        }
      }
      .course_overview_movie_actions {
        position: absolute;
        left: calc(50% - 44px);
        top: calc(50% - 44px);
        .MuiButtonBase-root {
          color: #fff;
          svg {
            font-size: 4.5rem;
          }
        }
      }
      .course_overview_quickview_buttons {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        .MuiButtonBase-root {
          .MuiSvgIcon-root {
            fill: #fff;
            width: 1.5em;
            height: 1.5em;
          }
        }
      }
    }
    .course_overview_quickview_progress {
      background: #760f4e12;
      padding: 16px;
      p {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  .MuiChip-root {
    top: 10px;
    border-radius: 0px 16px 16px 0px;
    .MuiChip-label {
      padding-right: 16px;
    }
  }
  &.open {
    .MuiChip-root {
      background: rgba(140, 120, 100, 0.5);
    }
  }
  &.comingsoon {
    .MuiChip-root {
      background: rgba(45, 159, 255, 0.7);
    }
  }
  &.due {
    .MuiChip-root {
      background: rgba(255, 45, 45, 0.7);
    }
  }
}
