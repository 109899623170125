/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.marketing{
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
    .action_button{
        background-color: #fff;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        border: none;
        &:hover{
            color: #fff!important;
        }
    }
    .MuiListItem-root{
        padding: 0;
        .MuiTypography-root{
            margin: 0;
        }
    }
    &.primary{
        h1{
            font-size: 2em;
            color: #fff;
        }
        p{
            color: #fff;
        }
        .MuiListItem-root{
            .MuiTypography-root{
                color: #fff;
            }
            svg{
                fill: #fff;
            }
        }
    }
    &.secondary{
        h1{
            font-size: 2em;
            color: #ffffff;
        }
        p{
            color: #ffffff;
        }
        .MuiListItem-root{
            .MuiTypography-root{
                color: #ffffff;
            }
            svg{
                fill: #ffffff;
            }
        }
    }
    &.hidelist{
        .MuiCard-root{
            display: flex;
        }
        .action_button{
           align-self: center;
        }
        .MuiCardActions-root{
            margin-left: auto;
        }
    }
}