/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.MuiDialog-root.intro_dialog{
    blockquote{
        background-color: #f6f6f6; // Background color
        border-left: 4px solid #000; // Left border color and width
        padding: 10px 20px; // Padding inside the blockquote
        margin: 10px 0; // Margin around the blockquote
        font-style: italic; // Italic text style

        // Style the citation source (optional)
        cite {
            display: block;
            margin-top: 10px;
            font-style: normal; // Reset font style for citation
            color: #555; // Color for the citation text
        }
    }
}