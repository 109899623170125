/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.colophon_overview {
    width: 100%;
    margin-top: 80px;
    .colophon_overview_box{
        padding: 16px;
        .colophon_box{
            margin-top: 16px;
            margin-bottom: 16px;
            padding: 16px;
            h1{
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 16px;
            }
            a{
                color: #000;
            }
        }
    }
}