// ========== DEFAULT VALUES ========================
// $main-bg-color: #00000005;
$main-bg-color: #eef2fa;
$main-bg-color-light: #eef2fa40;
$main-border-radius: 20px;
$main-toolbar-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
$text-dark-blue: #2b3674;
// [all / left / right / none]
$box-shadow-item: 'none';
// ======
$box-shadow: 0px 20px 40px rgba(112, 144, 176, 0.25);
$button-shadow: 0px 20px 40px -10px rgba(112, 144, 176, 0.12);
$button-radius: 10px;
// ===
// box settings
$box-bg-color: white;
$box-padding: 30px;
$box-border-radius: $main-border-radius;
// input fields
// $input-border-color: #ffffff00;
$input-bg-color: white;
$input-border-color: transparent;
$input-border-radius: 7px;
// $input-box-shadow: 0px 15px 33.3333px rgba(112, 144, 176, 0.12);
$input-box-shadow: none;
// image cards
$image-box-border-radius: $box-border-radius;
$image-box-bg-color: white;
$image-box-padding: 10px;
// preview-video
$video-bg-color: #c0c0c042;
$video-width: 400px;
$video-height: 200px;
// =========== MIXINS ==============================
@mixin box-container($shadow: false) {
  background: $box-bg-color;
  padding: $box-padding;
  border-radius: $box-border-radius;
  @if $shadow == $box-shadow-item {
    box-shadow: $box-shadow;
  }
  @if $box-shadow-item == 'all' {
    box-shadow: $box-shadow;
  }
}

@mixin input-fields-settings() {
  .MuiInputBase-root {
    box-sizing: border-box;
    border: 1px solid #919eab57;
    border-radius: $input-border-radius;
    background: $input-bg-color;
    box-shadow: $input-box-shadow;
    transition: border 0.5s;
    .MuiOutlinedInput-notchedOutline {
      border-color: $input-border-color;
    }
    &:hover {
      transition: border 0.5s;
      border: 1px solid silver;
    }
  }
}

@mixin divider-settings() {
  .MuiDivider-root {
    color: #607d8b;
    font-weight: 600;
    font-size: 13px;
  }
}
@mixin button-settings() {
  .MuiButton-root {
    border-radius: $button-radius;
    box-shadow: $button-shadow;
  }
}

@mixin tags-item($name, $tag-bg-color) {
  .tags_#{$name} {
    .MuiAutocomplete-tag {
      // background : $tag-bg-color;
    }
  }
}
@mixin image-settings() {
  img {
    border-radius: $box-border-radius;
    height: auto;
    max-height: 300px;
  }
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: none;
  box-shadow: none;
}
.ck-read-only {
  color: #848282;
}

// ============== MAIN STYLE CODE ==================
.form__actions_bar {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 290px);
  padding: 20px;
  margin-left: -16px;
  background: #eef2fa;
  text-align: right;
  box-shadow: 1px -3px 10px #00000012;
}
.formActionBar {
  position: fixed;
  bottom: 5px;
  background: white;
  z-index: 1;
  box-shadow: 1px 0px 10px #00000033;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}
// #mainPaper
.admin-course-layout {
  background: $main-bg-color;
  // background: #f3f7fe;
  margin-top: 0px;
  max-width: 100%;
  padding: 0px;
  .no-border {
    .MuiInputBase-root {
      border: 1px solid white !important;
    }
  }
  &.draweropen {
    .formActionBar {
      right: 15px;
      left: 300px;
      transition: ease left 0.3s;
    }
  }
  &.false {
    .formActionBar {
      right: 15px;
      left: 80px;
      transition: ease left 0.3s;
    }
  }

  @include input-fields-settings();
  @include image-settings();
  // @include button-settings();
  @include divider-settings();

  .header-title {
    color: $text-dark-blue;
  }
  .MuiDrawer-paperAnchorLeft {
    border-right: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .MuiToolbar-root {
    background: transparent;
    // background: $main-bg-color;
    padding-left: 0;
    // box-shadow: $main-toolbar-shadow;
  }

  .edit-course {
    .new-course-tabs {
      .new-course-tabs-container {
        padding: 15px;
        .MuiTabs-scroller {
          flex: 0 1 auto;
          width: auto;
          .MuiTabs-flexContainer {
            background: white;
            border: 10px solid white;
            border-radius: 15px;
            display: flex;
            flex-flow: row wrap;
            gap: 10px;
            button {
              min-height: 40px;
              display: flex;
              flex: 1 1 auto;
              &.MuiButtonBase-root {
                &.MuiTab-root {
                  border-radius: 15px;

                  // &:not(:last-child ){
                  //   margin-right: 15px;
                  // }
                  &:not(.Mui-selected) {
                    background: #eef2fb;
                  }
                  &.Mui-selected {
                    color: white;
                  }
                }
              }
            }
          }
          .MuiTabs-indicator {
            display: none;
          }
        }
      }
    }
    .formContainer-left {
      .tab-panel {
        flex-direction: column;
        max-width: 100%;
        overflow: auto;
        background: white;
        margin-left: 15px;
        margin-right: 15px;
        padding: 15px;
        padding-top: 0px;
        border-radius: 15px;
      }
    }
  }

  .formContainer {
    // margin-top: 0px;
    .formContainer-left {
      padding-top: 0px;
      .box-left {
        padding: 10px;
        // @include box-container($shadow: "left");
      }
    }
    .formContainer-right {
      padding-top: 15px;
      .box-right {
        // @include box-container($shadow: "right");
        padding: 10px;
        padding-top: 0px;
        background: white;
        border-radius: 10px;
        z-index: 1;
        box-shadow: -4px 2px 7px #0000001a;

        // UGLY
        .grid6-6 {
          width: 100%;
          margin-left: 0px;
          margin-top: 0px;
          margin-bottom: 0px;
          padding-left: 8px;
          .MuiGrid-item {
            margin-top: 0px;
            margin-bottom: 0px;
            // padding: 0px;
            .MuiFormControl-root {
              margin-top: 10px;
            }
          }
        }
      }
      .img-box {
        box-shadow: 0px 15px 33.3333px rgba(112, 144, 176, 0.12);
        // background: $image-box-bg-color;
        background: #00000012;
        padding: $image-box-padding;
        border-radius: $image-box-border-radius;
        &.large {
          width: 100%;
        }
        &.small {
          width: 50%;
        }
        .imgb-box-picture {
          border-radius: 20px;
          overflow: hidden;
          position: relative;
          background: #fafafaad;
        }
        .img-box-picture-name {
          font-size: 13px;
          color: $text-dark-blue;
          padding: 5px;
        }
        .img-box-padding {
          padding: 8px 0px;
        }
      }
    }
  }
  // HTML EDITOR
  .container-editor {
    position: relative;
    // padding: 16px 0px;
    .title-label {
      // background: white;
      display: inline-flex;
      padding: 3px 5px;
      margin-top: -10px;
      position: absolute;
      margin-left: 10px;
    }
    .ck-content {
      margin-top: 0px;
      border-radius: $input-border-radius;
      background: $input-bg-color;
      box-shadow: $input-box-shadow;
      padding: 16px;
    }
  }

  .container-images {
    margin-left: -16px;
    padding-right: 16px;
    width: calc(100% + 16px);
    background: $box-bg-color;
    border-radius: $box-border-radius;
    // box-shadow: $box-shadow;
    .MuiGrid-item {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    .img-box {
      background: $image-box-bg-color;
      padding: $image-box-padding;
      border-radius: $image-box-border-radius;
      .img-box-padding {
        padding: 16px 0px;
      }
    }
    .preview-video {
      background: $video-bg-color;
      width: $video-width;
      height: $video-height;
      border-radius: $image-box-border-radius;
      padding: $image-box-padding;
    }
  }
}

// .MuiGrid-root{
//   &.MuiGrid-container{
//     &.formContainer{
//       margin-top: 0px;
//       margin-left: 0px;
//       width:100%;
//       &.MuiOutlinedInput-root {
//         background: white;
//       }
//     }
//   }
// }

@include tags-item('category', #03a9f4ba);
@include tags-item('competence', #03a9f4ba);
@include tags-item('authors', #03a9f4ba);
@include tags-item('reviewers', #03a9f4ba);
// .tags_category {
//   .MuiAutocomplete-tag {
//     background: #03a9f4ba;
//   }
// }
