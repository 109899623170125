.custom_table {
  width: 100%;
  margin-bottom: 16px;
  border-radius: 12px;
  .table_container {
    .styledTableRow {
      &:hover {
        cursor: pointer;
        color: rgb(43, 54, 116);
        box-shadow: 0px 30px 15px -26px rgba(0, 0, 0, 0.2);
      }
    }
    .courseStudents {
      display: inline-flex;
      margin-right: 12px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background: rgba(121, 176, 159, 0.1);
    }
  }
}
