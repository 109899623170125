/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

footer {
  width: 100%;
  padding: 16px;
  padding-top: 32px;
  padding-bottom: 0px;
  margin-top: 32px;
  a {
    color: #000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .MuiGrid-container {
    margin: 0 auto;
    align-items: center;
    h5 {
      font-size: 18px;
    }
    &.copyright {
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid #dbdbdb;
      .MuiGrid-root:nth-child(2) {
        justify-content: center;
      }
      @media (max-width: $breakpoint-ms) {
        .MuiGrid-root {
          text-align: center;
          justify-content: center;
          margin-bottom: 5px;
          &:nth-child(2) {
            justify-content: center;
          }
        }
      }
    }
  }
  .MuiListItem-root {
    padding-left: 0px;
    padding-bottom: 0px;
    .MuiListItemIcon-root {
      min-width: auto;
      padding-right: 16px;
    }
  }
  .accreditations {
    justify-content: center;
    display: flex;
    gap: 20px;
    img {
      width: 100px;
    }
  }
}

.general_dialog {
  .MuiPaper-root {
    padding: 30px;
    max-height: 400px;
    .MuiDialogContent-root {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    @media (max-width: $breakpoint-ms) {
      max-height: 100%;
    }
  }
  .dialog_subtitle {
    display: flex;
    justify-content: center;
    padding-bottom: 19px;
  }
  .dialog_title {
    h2 {
      position: relative;
      text-align: center;
      overflow: hidden;
      text-shadow: 0px 1px #a3aed0;
    }
    h2:before,
    h2:after {
      position: absolute;
      top: 51%;
      overflow: hidden;
      width: 49%;
      height: 3px;
      content: '\a0';
      background-color: #a3aed0;
    }
    h2:before {
      margin-left: -50%;
      text-align: right;
    }
    h2:after {
      text-align: right;
      margin-left: 1%;
    }
  }
}

.contact_button.MuiButtonBase-root.MuiListItem-root {
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
}
