.scroll_top {
  position: fixed;
  bottom: 77px;
  z-index: 2000;
  font-weight: 700;
  text-transform: uppercase;
  padding: 9px 2px;
  transition: transform 0.3s;
  right: 17px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  color: white;
  -webkit-animation: fadeinout 6s linear forwards;
  animation: fadeinout 6s linear forwards;
}