.attedanceSheet{
  .cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0%;
    padding: 8px;
    gap:  10px ;
    border-radius: 8px;
    height: 90px;

    @media (min-width: $breakpoint-ms) {
      flex-direction: row;
      gap:16px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
    .statistic_box{
      box-shadow: 0px 10px 25px 0px #7090b01f;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 24px;
      padding-block: 16px;
      height: 56px;
      width: 64px;
      background: #2B36741A;
      border-radius: 8px;
      
    }
    .MuiTypography-root {
      font-size: 12px;
      line-height: 1.25rem;
      @media (min-width: $breakpoint-lg) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }
}