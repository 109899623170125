/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;
.date-btn-open {
  background-color: #f8f8f8;
  color: #2b3674;
  &:hover {
    background-color: #347ae2;
    color: #ffffff;
  }

  .quarter-btn-txt {
    opacity: 0.7;
    font-weight: 400;
  }
}
.date-btn-closed {
  background-color: #a9a9a9;
  .quarter-btn-txt {
    color: #ffffff;
    font-weight: 700;
  }
  &:hover {
    background-color: #a9a9a9;
    cursor: not-allowed !important;
  }
}

.date-btn-registered {
  .quarter-btn-txt {
    color: #ffffff;
    font-weight: 700;
  }
  &:hover {
    background-color: #ff3b30;
  }
}

.date-btn-unsubscribe {
  background-color: #ff3b30;
  .quarter-btn-txt {
    color: #ffffff;
    font-weight: 400;
  }
  &:hover {
    background-color: #6d6e71;
  }
}

.date-btn-signup {
  background-color: #347ae2;
  .quarter-btn-txt {
    color: #ffffff;
    font-weight: 400;
  }
  &:hover {
    background-color: #6d6e71;
  }
}
