/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

$boxShadow: 0 0 30px rgba(0, 0, 0, 0.25);
@mixin transitionBoxshadow {
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin transitionItem($property) {
  -webkit-transition: $property 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: $property 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.teacherDetail {
  .imageBox {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: #fff;
    gap: 2rem;
    border-radius: 0.5rem;

    @media (min-width: $breakpoint-ms) {
      flex-direction: row;
      align-items: flex-start;
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 1rem;
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }

  .clamp {
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  h2 {
    font-size: 20px;
  }

  .teacherCourse {
    margin-top: 2rem;
    @media (min-width: $breakpoint-ms) {
      margin: 2rem;
      margin-left: 1rem;
    }
  }

  .course_overview_card {
    height: 100%;
    &.nonTrialCourse {
      filter: grayscale(100%);
      opacity: 0.5;
      .favourite_icon {
        display: none;
      }
    }

    .course_img::after {
      content: '';
      background: #00000054;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      position: absolute;
      pointer-events: none;
    }

    .course_img {
      .MuiChip-root {
        z-index: 100;
      }

      .like_icon {
        z-index: 100;
      }

      .favourite_icon {
        z-index: 100;
      }
    }

    .done_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      //background: white;
      line-height: 70px;
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      text-align: center;
      z-index: 10;

      .MuiSvgIcon-root {
        width: 41px;
        height: 37px;
        color: white;
      }
    }
  }

  .card__wrapper {
    .badge_course_status {
      line-height: 21px;
      height: auto;
      color: white;
      background-color: #ff9800e8 !important;
      border: 2px solid white;
      box-shadow: 1px 2px 15px #0000005e;

      .MuiChip-label {
        padding: 0px 8px !important;
      }
    }

    .card__authors {
      .authors-wrapper {
        display: flex;
        flex-flow: wrap;
        gap: 1%;
        flex: 1 1 auto;

        .chip_author {
          top: 0px !important;
          left: 0px !important;
          padding: 0px;
          margin: 0px;
          margin-top: 2px;
          height: auto;
          position: relative !important;
          border: none !important;
          border-radius: 5px;
          background: #f8f8f8;
          // background: #26899E;
          color: #2c3331;

          .MuiChip-label {
            padding: 0px 6px !important;
            font-size: 13px !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    .card__categories {
      position: absolute;
      bottom: 12px;
      left: 0px;
      padding: 0px 16px;
      width: 100%;
      display: flex;
      flex-flow: wrap;
      grid-gap: 1%;
      gap: 1%;
      flex: 1 1 auto;

      .chip_category {
        top: 0px !important;
        left: 0px !important;
        padding: 0px 4px;
        margin: 0px;
        margin-top: 3px;
        height: auto;
        position: relative !important;
        border-radius: 9px !important;
        box-shadow: 0px 0px 12px #00000066;
        // TODO: change border color to theme color
        border: 1px solid #2a4a92;
        background: #ffffffd6 !important;
        // background: #FFBD60 !important;
        .MuiChip-label {
          padding: 0px 4px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          font-style: italic;
          // TODO: change font color to theme color
          color: #2a4a92;
          // color: #3F51B5;
        }
      }
    }

    .card__image {
      display: flex;
      // height: auto;
      img {
        height: auto;
      }
    }

    .card__content {
      transition: all 0.2s;
      display: flex;
      flex-direction: column;
      height: auto;

      .card__title {
        padding: 16px;
        display: flex;
        margin-bottom: auto;

        .course_title {
          .course_title_main {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
          }

          .course_title_sub {
            font-size: 0.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: auto;
          }
        }
      }

      .card__authors {
        padding: 0px 16px;
        display: flex;
        margin-top: auto;
      }

      .card__symbols_points {
        padding: 16px;
        display: flex;
        justify-content: space-between;

        .card__symbols {
          display: flex;
          align-items: center;
          justify-content: center;

          .course_overview_card_accreditations {
            display: flex;
            flex-flow: wrap-reverse;
            gap: 3px;
          }
        }

        .card__points {
          display: flex;
          align-items: flex-end;
          height: 100%;
          gap: 6px;

          .podcast_wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            .podcast_icon {
              padding: 6px;

              .MuiSvgIcon-root {
                width: 15px;
                height: 15px;
              }
            }
          }

          .duration_icon {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .MuiCard-root {
    width: -webkit-fill-available;
    width: -moz-available;
    border-radius: 20px;
    box-shadow: none;

    h1 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      height: 46.8px;
      font-size: 16px;
    }

    .MuiCardMedia-root {
      border-radius: 20px 20px 0px 0px;
      height: 194px;
    }

    .MuiCardContent-root {
      display: grid;
      gap: 4px;
      height: 130.4px;
      padding: 19px 16px 22px 16px;
    }

    .MuiCardActions-root {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px;

      .podcast_icon {
        border-radius: 70px;
        padding: 6px;

        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
        }
      }
    }

    cursor: pointer;
    height: 100%;
    justify-content: space-between;
    display: inline-flex;
    flex-direction: column;
    @media (max-width: $breakpoint-ms) {
      display: block;
    }

    img {
      transition: ease all 0.75s;
      transform-origin: 50% 100%;
    }

    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);

      img {
        transform: scale(110%);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
      }
    }

    .MuiChip-root {
      position: absolute;
      top: 18px;
      left: 15px;
      border-radius: 70px;
      background-color: white;

      .MuiChip-label {
        padding-right: 16px;
        font-weight: 700;
        font-size: 14px;
      }
    }

    .favourite_icon {
      position: absolute;
      top: 18px;
      right: 16px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;

      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }

    .like_icon {
      position: absolute;
      top: 18px;
      right: 52px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;

      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }

    .course_overview_card_accreditations {
      display: flex;

      .MuiAvatar-root {
        margin-left: 2.5px;
        margin-right: 2.5px;
        width: 25px;
        height: 25px;
        font-size: 14px;
        border: 1px solid;

        &.chip {
          border: none;
        }
      }
    }

    .duration_icon {
      color: #fff;
      padding: 5px 16px;
      font-weight: 500;
      font-size: 14px;
      height: 34px;
      border-radius: 70px;
      display: flex;
      align-items: center;
    }

    .MuiButtonBase-root {
      font-size: 15px;
    }
  }
}

.pagination {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.teacherOverview {
  display: flex;
  flex-direction: column;
  //margin-top: 80px;
  min-height: calc(100vh - 120px);
  box-shadow: none;
  @include transitionBoxshadow;

  //@media (max-width: $breakpoint-ms) {
  //  margin-top: 268px;
  //}

  .teachers_overview_box {
    padding: 16px;
  }

  .teacherCard {
    position: relative;
    display: flex;
    border-radius: 1rem;
    width: 100%;

    .fullCard {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      padding: 0.75rem;
      border-radius: 1rem;
      background-color: white;
      opacity: 1;
      // @media (min-width: $breakpoint-ms) { padding: 1.25rem; }
      .teacher-content {
        overflow: hidden;
        position: relative;
        border-radius: 0.5rem;
        width: 100%;

        .teacher-image {
          height: 256px;
          width: 100%;

          img {
            border-radius: 0.5rem;
            height: 100%;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            object-fit: cover;
          }
        }

        h2 {
          padding-top: 10px;
        }

        .teacher-name {
          display: flex;
          font-size: 0.9rem;
          margin-top: 16px;
        }
      }

      @media (min-width: $breakpoint-ms) {
        border-radius: 1rem;
      }

      .MuiAvatar-root {
        height: 256px;
        width: inherit;
        border-radius: 1rem;
      }
    }

    .readBio {
      position: absolute;
      border-radius: 1rem;
      height: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      cursor: pointer;
      transition: opacity ease-in-out 0.25s;

      .MuiButtonBase-root {
        position: absolute;
        top: 50%;
        opacity: 0;
        background-color: #fff;
        font-size: 0.75rem;
        border-radius: 0.5rem;
        line-height: 1rem;
        transform: translate(-50%, -50%);
        left: 50%;
        transition: opacity ease-in-out 0.25s;
      }
    }

    .readBio:hover {
      opacity: 0.9;

      .MuiButtonBase-root {
        opacity: 1;
      }
    }
  }

  .noTeachers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 39vh;
    gap: 46px;

    .school_icon {
      background-color: white;
      border-radius: 70px;
      height: 106px;
      width: 106px;

      .MuiSvgIcon-root {
        width: 3em;
        height: 2em;
      }
    }
  }
}

.teacherSearch {
  display: flex;
  flex-direction: row;
  border-radius: 0.75rem;
  padding: 0.5rem;
  background-color: #fff;
  gap: 0.75rem;
  border: 0;

  .searchIcon {
    color: white;
    border-radius: 16px;
    flex-grow: 0;
    padding: 6px 16px;
  }

  .searchIcon:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
