.tabBarPackages {
  .css-lfwcke-MuiTabs-flexContainer {
    border-color: var(--divider-color);
    min-width: 158px;
    border-radius: 4px;
  }
  .MuiButtonBase-root.MuiTab-root {
    text-transform: none;
  }
  .MuiTab-root:last-child {
    .MuiTouchRipple-root {
      border-bottom: none;
    }
  }
  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: white;
    .MuiTouchRipple-root {
      border-bottom: none;
    }
  }
  .tabLabel {
    display: flex;
    align-items: center;
    gap: 9%;
  }
  .tabs_badge {
    position: absolute;
    right: 10px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: block;
    line-height: 25px;
    color: #000;
    font-size: 10px;
    background-color: white;
  }
  .MuiTouchRipple-root {
    width: 88%;
    left: 7%;
  }
  .MuiPaper-root.MuiAccordion-root {
    box-shadow: none;
    border-top: 1px solid var(--divider-color);
    border-radius: 0px;
  }
  .MuiPaper-root.MuiAccordion-root:first-child {
    border-radius: 0px;
  }
  .MuiAccordionSummary-content {
    font-weight: bolder;
  }
  .MuiAccordionDetails-root {
    padding: 0px;
  }
}
