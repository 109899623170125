/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.faq_searchBar {
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  height: 45vh;
  width: 100%;
  .searchBar_section {
    width: 100%;
    margin-top: 64px;
    .searchBar_headline {
      text-align: center;
      padding: 2%;
    }
    @media (max-width: $breakpoint-sm) {
      .searchBar_headline {
        padding-bottom: 4%;
        font-size: 23px;
      }
    }
    .searchBar_inputContainer {
      text-align: center;
    }
    .searchBar_input {
      width: 54%;
      background-color: white;
      border-radius: 18px;
      .MuiInputBase-root.MuiOutlinedInput-root {
        border-radius: 18px;
      }
    }
    @media (max-width: $breakpoint-sm) {
      .searchBar_input {
        width: 86%;
        margin-top: 10px;
      }
    }
  }
}

.faq_main {
  width: 100%;
  .faq_category_section {
    max-width: 1200px;
    margin: 0% auto;
    .faq_card {
      margin-bottom: 2%;
      box-shadow: none;
      border: none;
      .MuiPaper-root.MuiAccordion-root {
        box-shadow: none;
      }
      .faq_card_header {
        font-weight: 800;
      }
      .MuiCardHeader-root {
        padding: 0px;
      }
      .MuiCardHeader-root {
        padding: 0px;
      }
      .MuiCardHeader-root {
        padding: 0px;
      }
    }
  }
  @media (max-width: $breakpoint-ipad) {
    .faq_category_section {
      padding: 16px 39px 16px 16px;
      .faq_card_header {
        .MuiTypography-root {
          font-size: 23px;
        }
      }
    }
  }
}
.question_buttons.MuiButtonBase-root.MuiListItem-root {
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
}

.faq_article {
  .MuiButtonBase-root.MuiButton-root {
    background-color: rgba(118, 15, 78, 0.04);
    &:hover {
      background-color: #760f4e;
      color: white;
    }
  }
}
