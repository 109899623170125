div[class*='symbol-'] {
  color: white;
  background-color: Gray;
}

div.symbol {
  /* SYMBOL BACKGROUND COLORS */
  &-a {
    background-color: Thistle;
  }

  // &-b{background-color:AntiqueWhite;}
  &-b {
    background-color: #e2cfb6;
  }

  &-c {
    background-color: LightSkyBlue;
  }

  // &-d{background-color:Aquamarine;}
  &-d {
    background-color: #62c2a2;
  }

  &-e {
    background-color: DeepSkyBlue;
  }

  &-f {
    background-color: DarkMagenta;
  }

  &-g {
    background-color: DarkSalmon;
  }

  &-h {
    background-color: Blue;
  }

  &-i {
    background-color: BlueViolet;
  }

  &-j {
    background-color: Brown;
  }

  &-k {
    background-color: BurlyWood;
  }

  &-l {
    background-color: CadetBlue;
  }

  &-m {
    background-color: Chartreuse;
  }

  &-n {
    background-color: Chocolate;
  }

  &-o {
    background-color: Coral;
  }

  &-p {
    background-color: CornflowerBlue;
  }

  &-q {
    background-color: RosyBrown;
  }

  &-r {
    background-color: Crimson;
  }

  &-s {
    background-color: Cyan;
  }

  &-t {
    background-color: DarkBlue;
  }

  &-u {
    background-color: DarkCyan;
  }

  &-v {
    background-color: DarkGoldenRod;
  }

  &-w {
    background-color: SlateGray;
  }

  &-x {
    background-color: DarkGray;
  }

  &-y {
    background-color: DarkGreen;
  }

  &-z {
    background-color: DarkKhaki;
  }

  // CAPITALS
  // &-{color:DarkMagenta;}
  &-A {
    background-color: DarkOliveGreen;
  }

  &-B {
    background-color: Darkorange;
  }

  &-C {
    background-color: DarkOrchid;
  }

  &-D {
    background-color: DarkRed;
  }

  &-E {
    background-color: DarkSalmon;
  }

  &-F {
    background-color: DarkSeaGreen;
  }

  &-G {
    background-color: DarkSlateBlue;
  }

  &-H {
    background-color: DarkSlateGray;
  }

  &-I {
    background-color: DarkTurquoise;
  }

  &-J {
    background-color: #663399;
  }

  &-K {
    background-color: DeepPink;
  }

  &-L {
    background-color: DeepSkyBlue;
  }

  &-M {
    background-color: YellowGreen;
  }

  &-N {
    background-color: DodgerBlue;
  }

  &-O {
    background-color: FireBrick;
  }

  &-P {
    background-color: IndianRed;
  }

  &-Q {
    background-color: ForestGreen;
  }

  &-R {
    background-color: LightSeaGreen;
  }

  &-S {
    background-color: SlateBlue;
  }

  &-T {
    background-color: MediumSeaGreen;
  }

  &-U {
    background-color: Gold;
  }

  &-V {
    background-color: GoldenRod;
  }

  &-W {
    background-color: SlateGray;
  }

  &-X {
    background-color: DarkGray;
  }

  &-Y {
    background-color: Green;
  }

  &-Z {
    background-color: DarkOliveGreen;
  }

  // others
  &-Mp {
    background-color: OrangeRed;
  }

  // &-HoneyDew{color:HoneyDew;}
  // &-HotPink{color:HotPink;}
  // &-IndianRed{color:IndianRed;}
  // &-Indigo{color:Indigo;}
  // &-Ivory{color:Ivory;}
  // &-Khaki{color:Khaki;}
  // &-Lavender{color:Lavender;}
  // &-LavenderBlush{color:LavenderBlush;}
  // &-LawnGreen{color:LawnGreen;}
  // &-LemonChiffon{color:LemonChiffon;}
  // &-LightBlue{color:LightBlue;}
  // &-LightCoral{color:LightCoral;}
  // &-LightCyan{color:LightCyan;}
  // &-LightGoldenRodYellow{color:LightGoldenRodYellow;}
  // &-LightGray{color:LightGray;}
  // &-LightGrey{color:LightGrey;}
  // &-LightGreen{color:LightGreen;}
  // &-LightPink{color:LightPink;}
  // &-LightSalmon{color:LightSalmon;}
  // &-LightSeaGreen{color:LightSeaGreen;}
  // &-LightSkyBlue{color:LightSkyBlue;}
  // &-LightSlateGray{color:LightSlateGray;}
  // &-LightSlateGrey{color:LightSlateGrey;}
  // &-LightSteelBlue{color:LightSteelBlue;}
  // &-LightYellow{color:LightYellow;}
  // &-Lime{color:Lime;}
  // &-LimeGreen{color:LimeGreen;}
  // &-Linen{color:Linen;}
  // &-Magenta{color:Magenta;}
  // &-Maroon{color:Maroon;}
  // &-MediumAquaMarine{color:MediumAquaMarine;}
  // &-MediumBlue{color:MediumBlue;}
  // &-MediumOrchid{color:MediumOrchid;}
  // &-MediumPurple{color:MediumPurple;}
  // &-MediumSeaGreen{color:MediumSeaGreen;}
  // &-MediumSlateBlue{color:MediumSlateBlue;}
  // &-MediumSpringGreen{color:MediumSpringGreen;}
  // &-MediumTurquoise{color:MediumTurquoise;}
  // &-MediumVioletRed{color:MediumVioletRed;}
  // &-MidnightBlue{color:MidnightBlue;}
  // &-MintCream{color:MintCream;}
  // &-MistyRose{color:MistyRose;}
  // &-Moccasin{color:Moccasin;}
  // &-NavajoWhite{color:NavajoWhite;}
  // &-Navy{color:Navy;}
  // &-OldLace{color:OldLace;}
  // &-Olive{color:Olive;}
  // &-OliveDrab{color:OliveDrab;}
  // &-Orange{color:Orange;}
  // &-OrangeRed{color:OrangeRed;}
  // &-Orchid{color:Orchid;}
  // &-PaleGoldenRod{color:PaleGoldenRod;}
  // &-PaleGreen{color:PaleGreen;}
  // &-PaleTurquoise{color:PaleTurquoise;}
  // &-PaleVioletRed{color:PaleVioletRed;}
  // &-PapayaWhip{color:PapayaWhip;}
  // &-PeachPuff{color:PeachPuff;}
  // &-Peru{color:Peru;}
  // &-Pink{color:Pink;}
  // &-Plum{color:Plum;}
  // &-PowderBlue{color:PowderBlue;}
  // &-Purple{color:Purple;}
  // &-Red{color:Red;}
  // &-RosyBrown{color:RosyBrown;}
  // &-RoyalBlue{color:RoyalBlue;}
  // &-SaddleBrown{color:SaddleBrown;}
  // &-Salmon{color:Salmon;}
  // &-SandyBrown{color:SandyBrown;}
  // &-SeaGreen{color:SeaGreen;}
  // &-SeaShell{color:SeaShell;}
  // &-Sienna{color:Sienna;}
  // &-Silver{color:Silver;}
  // &-SkyBlue{color:SkyBlue;}
  // &-SlateBlue{color:SlateBlue;}
  // &-SlateGray{color:SlateGray;}
  // &-SlateGrey{color:SlateGrey;}
  // &-Snow{color:Snow;}
  // &-SpringGreen{color:SpringGreen;}
  // &-SteelBlue{color:SteelBlue;}
  // &-Tan{color:Tan;}
  // &-Teal{color:Teal;}
  // &-Thistle{color:Thistle;}
  // &-Tomato{color:Tomato;}
  // &-Turquoise{color:Turquoise;}
  // &-Violet{color:Violet;}
  // &-Wheat{color:Wheat;}
  // &-White{color:White;}
  // &-WhiteSmoke{color:WhiteSmoke;}
  // &-Yellow{color:Yellow;}
  // &-YellowGreen{color:YellowGreen;}
}
