/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.course_overview_list_min {
  padding: 16px;
  h1 {
    font-size: 18px;
  }
  h2 {
    margin-bottom: 0px;
  }
  // TODO: clean up this override
  .MuiGrid-root > .MuiGrid-item {
    padding-top: 0px;
  }
  .courseview_category_items {
    padding-top: 0px;
  }

  .card__wrapper {
    display: flex;
    flex: 1;
    position: relative;
    .card__container {
      margin: 0px;
      .course_overview_card_image {
        .card_image_wrapper {
          display: flex;
          flex: 1;
          background: silver;
        }
      }
      .course_overview_card_info {
        .card_info_wrapper {
          display: flex;
          flex: 1;
          flex-direction: column;
          .card__title {
            .course_title_main {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: auto;
            }
            .course_title_sub {
              font-size: 12px;
            }
          }
          .card__authors {
            padding-top: 10px;
            display: flex;
          }
          .card__categories {
            margin-top: auto;
            padding-top: 10px;
            flex: 0;
            .chip_category {
              box-shadow: none;
            }
          }
          .card__symbols__points {
            display: flex;
            padding-top: 10px;
            justify-content: space-between;
            margin-top: auto;
            .card__points {
              display: flex;
              gap: 3px;
              align-items: center;
            }
            .course_overview_card_accreditations {
              flex-wrap: wrap;
            }
            .duration_icon {
              white-space: nowrap;
            }
          }
        }
      }
    }
    &.hidden {
      &:hover {
        .hideCourse {
          transition: width 0.2s;
          transform: translateX(0px);
          width: 30px;
          // right: 0px;
          .test {
            transition: width 0.2s;

            width: 30px;
          }
        }
      }
    }
    .hideCourse {
      transition: width 0.2s;
      height: 100% !important;
      transform: translateX(30px);
      width: 0px;
      .test {
        // right: -40px;
        // transform: translate3d(0, 0, 0);
        transition: width 0.2s;
        height: 100%;
        opacity: 1;
        width: 0px;
        border-radius: 0px;
      }
    }
  }
  // ----------------- Course Overview List Min -----------------
  .MuiGrid-root {
    // width: inherit;
    // height: inherit;
    // margin: 0px;

    .course_overview_card {
      display: flex;
      flex: 1;
      // height: 120px;
    }
    &.course_overview_card_image {
      margin: 0px;
      overflow: hidden;
      .card_image_wrapper {
        display: flex;
      }
      // width: 20%;
      .done_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        line-height: 70px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        text-align: center;
        z-index: 10;
        .MuiSvgIcon-root {
          width: 41px;
          height: 37px;
          color: white;
        }
      }
    }
    &.course_overview_card_info {
      margin: 0px;
      // width: 80%;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      .card__categories {
        position: relative;
        bottom: auto;
        padding: 10px 0px;
        .chip_catagory {
          box-shadow: none;
        }
      }
    }
    .course_overview_card_info_detail {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 34px;
      gap: 6px;
    }
    @media (max-width: $breakpoint-ms) {
    }
    @media (max-width: $breakpoint-sm) {
      &.course_overview_card_info_main {
        padding-bottom: 8px;
        padding-top: 0px;
      }
      &.course_overview_card_info_detail {
        padding-top: 8px;
        div.MuiGrid-item {
          &:nth-child(2) {
            text-align: left;
            padding-right: 0px;
          }
        }
      }
    }
  }
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .MuiCard-root {
    cursor: pointer;
    // height: 100%;
    border-radius: 15px;
    box-shadow: none;
    .MuiCardContent-root {
      display: flex;
      padding: 0px;
      // height: 80%;
    }
    img {
      transition: ease all 0.75s;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
      img {
        transform: scale(110%);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
      }
    }
    .MuiChip-root {
      position: absolute;
      bottom: 0;
      border-radius: 0;
      .MuiChip-label {
        padding-right: 16px;
        font-size: 14px;
      }
    }
    .favourite_icon {
      position: absolute;
      top: 8px;
      right: 10px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .like_icon {
      position: absolute;
      top: 8px;
      right: 44px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .podcast_icon {
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .duration_icon {
      color: #fff;
      padding: 5px 16px;
      font-weight: 500;
      font-size: 14px;
      height: 25px;
      border-radius: 70px;
      text-align: center;
      display: flex;
      align-items: center;
    }
    &.course_overview_card_info_detail {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 34px;
      gap: 6px;
    }
    .MuiCardActions-root {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: auto;
      // height: 20%;
    }
    .course_overview_card_accreditations {
      display: flex;
      flex-wrap: wrap;
      .MuiAvatar-root {
        margin-left: 2.5px;
        margin-right: 2.5px;
        width: 25px;
        height: 25px;
        font-size: 14px;
        border: 1px solid;
        &.chip {
          border: none;
        }
      }
    }
    .MuiButtonBase-root {
      font-size: 15px;
    }
    &.open {
      .MuiChip-root {
        background: rgba(140, 120, 100, 0.5);
      }
    }
    &.comingsoon {
      .MuiChip-root {
        background: rgba(45, 159, 255, 0.7);
      }
    }
    &.due {
      .MuiChip-root {
        background: rgba(255, 45, 45, 0.7);
      }
    }
  }
  .courseview_category_introduction {
    margin-bottom: 16px;
  }
  // .hidden.MuiCard-root {
  //     &:hover {
  //         .MuiCardContent-root {
  //             padding-top: 9px;
  //             height: 93%;
  //         }
  //         .hideCourse {
  //             width: 100%;
  //             height: 30px;
  //             left: 0;
  //             .test {
  //                 bottom: 0px;
  //                 transform: translate3d(0, 0, 0);
  //                 transition: bottom 0.2s;
  //                 opacity: 1;
  //                 padding: 4px;
  //             }
  //         }
  //     }
  // }
}
