$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.course_related {
  background: #ffffff;
  color: #2b3674;
  font-size: 18px;
  position: relative;
  .carousel {
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
    .indicators {
      @media (min-width: $breakpoint-xl) {
        left: -1%;
      }
    }
    .indicatorsRight {
      @media (min-width: $breakpoint-xl) {
        left: 99%;
      }
    }

    .dragable {
      overflow: hidden;
      width: 100%;
      .inner {
        display: flex;
        gap: 9px;
        padding: 20px;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        .inner {
          display: flex;
          gap: 9px;
        }
      }
    }
  }
  .carousel-item {
    //width: 560px;
    .category {
      margin-bottom: 10px;
      .MuiTypography-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 560px;
      }
    }

    .course_overview_card {
      width: 362px;
      border-radius: 20px;
      padding-top: 17px;
      height: 100%;
      .course_img::after {
        content: '';
        background: #00000054;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: block;
        position: absolute;
        pointer-events: none;
      }
      .course_img {
        .MuiChip-root {
          z-index: 100;
        }
        .like_icon {
          z-index: 100;
        }
        .favourite_icon {
          z-index: 100;
        }
      }
      .MuiCard-root {
        width: -webkit-fill-available;
        width: -moz-available;
        border-radius: 20px;
        box-shadow: none;
        border: 1px solid #eeeeee;
        .done_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          //background: white;
          line-height: 70px;
          position: absolute;
          left: calc(50% - 30px);
          top: calc(50% - 30px);
          text-align: center;
          z-index: 10;
          .MuiSvgIcon-root {
            width: 41px;
            height: 37px;
            color: white;
          }
          p {
            margin-bottom: 0px;
          }
        }

        h1 {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 46.8px;
          margin-bottom: 0px;
        }

        .MuiCardMedia-root {
          border-radius: 20px 20px 0px 0px;
          height: 194px;
        }
        .MuiCardContent-root {
          display: grid;
          gap: 4px;
          height: 130.4px;
          padding: 19px 16px 22px 16px;
          p {
            margin-bottom: 0px;
          }
        }
        .MuiCardActions-root {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 8px;
          .podcast_icon {
            border-radius: 70px;
            padding: 6px;
            .MuiSvgIcon-root {
              height: 15px;
              width: 15px;
            }
          }
        }

        cursor: pointer;
        height: 100%;
        justify-content: space-between;
        display: inline-flex;
        flex-direction: column;
        @media (max-width: $breakpoint-ms) {
          display: flex;
        }
        img {
          transition: ease all 0.75s;
          transform-origin: 50% 100%;
        }
        &:hover {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
          img {
            transform: scale(110%);
            -webkit-transform: scale(1.1, 1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            -ms-transform: scale(1.1);
          }
        }
        .MuiChip-root {
          position: absolute;
          bottom: 0;
          border-radius: 0px;
          .MuiChip-label {
            padding-right: 16px;
            font-weight: 700;
            font-size: 14px;
          }
        }
        .favourite_icon {
          position: absolute;
          top: 18px;
          right: 16px;
          background-color: white;
          border-radius: 70px;
          padding: 6px;
          .MuiSvgIcon-root {
            height: 15px;
            width: 15px;
          }
        }
        .like_icon {
          position: absolute;
          top: 18px;
          right: 52px;
          background-color: white;
          border-radius: 70px;
          padding: 6px;
          .MuiSvgIcon-root {
            height: 15px;
            width: 15px;
          }
        }
        .course_overview_card_accreditations {
          display: flex;
          .MuiAvatar-root {
            margin-left: 2.5px;
            margin-right: 2.5px;
            width: 25px;
            height: 25px;
            font-size: 14px;
            border: 1px solid;
            &.chip {
              border: none;
            }
          }
        }

        .duration_icon {
          color: #fff;
          padding: 5px 16px;
          font-weight: 500;
          font-size: 14px;
          height: 34px;
          border-radius: 70px;
          text-align: center;
          display: flex;
          align-items: center;
        }
        .MuiButtonBase-root {
          font-size: 15px;
        }
      }
    }

    @media (max-width: $breakpoint-sm) {
      width: 250px;
    }
  }

  .active {
    // margin: 0px calc((100vw - 560px) / 2);
    @media (max-width: $breakpoint-sm) {
      margin: 0px;
    }
  }
}
