:root {
  --border-radius: 34px;
  --width-gallery: 100%;
  --height-gallery: 300px;
  --font-size-large: 24px;
  --font-size-medium: 18px;
  --font-size-small: 16px;
  --font-size-smallest: 13px;
  --breakpoint-sm: 600px; /* Example breakpoint */
}

/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.course_overview {
  width: 100%;
  min-height: 100vh;

  .nonTrialCourse {
    filter: grayscale(100%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    .favourite_icon {
      display: none;
    }
  }

  .special_access {
    .card__wrapper {
      &.hidden {
        &:hover {
          // .card__img { transform: translateY(-40px); }
          .card__content {
            transition: all 0.2s;
            background: white;
            transform: translateY(0px) !important;
          }
        }
      }
    }
  }

  // NEW CARD DESIGN
  .courseview_category {
    margin-bottom: 0px;
    .courseview_category_title {
      font-size: 30px;
    }

    &:first-child {
      padding-top: 0px !important;
    }

    padding-top: 50px !important;
  }

  .course_overview_grid {
    .card__wrapper {
      &.hidden {
        &:hover {
          // .card__img { transform: translateY(-40px); }
          .card__content {
            transition: all 0.2s;
            background: white;
            transform: translateY(-40px);
          }
        }
      }
    }

    .hidden {
      $transitionValue: 0.2s;
      transition: all $transitionValue;

      .cours_img {
        transition: all $transitionValue;
        transform: translate3d(0, 0, 0);
      }

      .MuiCardContent-root {
        transition: all $transitionValue;
      }

      .MuiCardActions-root {
        transition: all $transitionValue;
      }

      .hideCourse {
        height: 0px;
        // -webkit-transition: all ease-in-out 0.2s;
        .test {
          bottom: -40px;
          transform: translate3d(0, 0, 0);
          transition: bottom 0.2s;
          opacity: 1;
          width: 100%;
          border-radius: 0px;
        }
      }

      &:hover {
        transition: all $transitionValue;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);

        img {
          transition: scale() $transitionValue;
          transform: scale(110%);
          -webkit-transform: scale(1.1, 1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
        }

        .course_img {
          transition: all $transitionValue;
          transform: translate3d(0, -12px, 0);
        }

        .MuiCardContent-root {
          transition: all $transitionValue;
          transform: translate3d(0, -20px, 0);
        }

        .MuiCardActions-root {
          transition: all $transitionValue;
          transform: translate3d(0, -33px, 0);
        }

        .hideCourse {
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 40px;
          transition: height $transitionValue;

          .test {
            bottom: 0px;
            transform: translate3d(0, 0, 0);
            transition: bottom 0.2s;
            opacity: 1;
          }
        }

        @media (width: $breakpoint-ipad) {
          display: block;
          &:hover {
            .hideCourse {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .card__wrapper {
    .badge_course_status {
      line-height: 31px;
      height: auto;
      color: white;
      box-shadow: 1px 2px 15px #0000005e;
      width: 100%;

      .MuiChip-label {
        padding: 0px 8px !important;
      }
    }

    .card__authors {
      .authors-wrapper {
        display: flex;
        flex-flow: wrap;
        gap: 1%;
        flex: 1 1 auto;

        .chip_author {
          top: 0px !important;
          left: 0px !important;
          padding: 0px;
          margin: 0px;
          margin-top: 2px;
          height: auto;
          position: relative !important;
          border: none !important;
          border-radius: 5px;
          background: #f8f8f8;
          // background: #26899E;
          color: #2c3331;

          .MuiChip-label {
            padding: 0px 6px !important;
            font-size: 13px !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    .card__categories {
      position: absolute;
      bottom: 34px;
      left: 0px;
      padding: 0px 16px;
      width: 100%;
      display: flex;
      flex-flow: wrap;
      grid-gap: 1%;
      gap: 1%;
      flex: 1 1 auto;

      .chip_category {
        left: 0px !important;
        margin: 0px;
        margin-top: 3px;
        height: auto;
        position: relative !important;
        border-radius: 9px !important;
        box-shadow: 0px 0px 12px #00000066;
        // TODO: change border color to theme color
        border: 1px solid #2a4a92;
        background: #ffffffd6 !important;
        // background: #FFBD60 !important;
        .MuiChip-label {
          padding: 0px 4px !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          font-style: italic;
          // TODO: change font color to theme color
          color: #2a4a92;
          background: #ffffffd6;
          border-radius: 9px;
        }
      }
    }

    .card__categories.noStatus {
      bottom: 6px;
    }

    .card__image {
      display: flex;
      // height: auto;
      img {
        height: auto;
      }
    }

    .card__content {
      transition: all 0.2s;
      display: flex;
      flex-direction: column;
      height: 100%;

      .card__title {
        padding: 16px;
        display: flex;
        margin-bottom: auto;

        .course_title {
          .course_title_main {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: auto;
          }

          .course_title_sub {
            font-size: 0.8rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: auto;
          }
        }
      }

      .card__authors {
        padding: 0px 16px;
        display: flex;
        margin-top: auto;
      }

      .card__symbols_points {
        padding: 16px;
        display: flex;
        justify-content: space-between;

        .card__symbols {
          display: flex;
          align-items: center;
          justify-content: center;

          .course_overview_card_accreditations {
            display: flex;
            flex-flow: wrap-reverse;
            gap: 3px;
          }
        }

        .card__points {
          display: flex;
          align-items: flex-end;
          height: 100%;
          gap: 6px;
          align-items: center;

          .podcast_wrapper {
            display: flex;
            align-items: center;
            height: 100%;

            .podcast_icon {
              padding: 6px;

              .MuiSvgIcon-root {
                width: 15px;
                height: 15px;
              }
            }
          }

          .duration_icon {
            white-space: nowrap;
          }
        }
      }
    }
  }

  // END NEW CARD DESIGN
  .course_overview_card {
    height: 100%;

    .course_img::after {
      content: '';
      background: #00000054;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      display: block;
      position: absolute;
      pointer-events: none;
    }

    .course_img {
      .MuiChip-root {
        z-index: 100;
      }

      .like_icon {
        z-index: 100;
      }

      .favourite_icon {
        z-index: 100;
      }
    }

    .done_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      //background: white;
      line-height: 70px;
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      text-align: center;
      z-index: 10;

      .MuiSvgIcon-root {
        width: 41px;
        height: 37px;
        color: white;
      }
    }
  }

  .course_overview_wrap {
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: calc(100% - 386px);
    @media (max-width: $breakpoint-ipad) {
      width: 100%;
    }
  }

  .course_overview_wrap_filter {
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;

    .course_overview_grid {
      @media (min-width: $breakpoint-lg) {
        .MuiGrid-root {
          flex-basis: 25%;
        }
      }
      @media (min-width: 1800px) {
        .MuiGrid-root {
          flex-basis: 20%;
        }
      }
    }
  }

  //   .course_overview_list{
  //     .card__wrapper .card__categories{
  //            bottom: 12px;
  //     }
  //  }
  .course_overview_tabs {
    padding: 16px;
    align-items: center;
    margin-bottom: 0px;

    .MuiGrid-item {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
    }

    @media (max-width: $breakpoint-ipad) {
      padding-right: 0px;
    }

    .course_overview_tabs {
      width: fit-content;
      background-color: #fff;
      border-radius: 20px;
      @media (max-width: 1109px) {
        width: inherit;
      }

      .MuiTabs-root {
        min-height: 41px;
      }

      .MuiTabs-indicator {
        display: none;
      }

      .MuiTabs-flexContainer {
        gap: 12px;

        .MuiButtonBase-root {
          border-radius: 15px;
          padding: 10px 18px;
          text-transform: none;
          min-height: 41px;

          &:not(.Mui-selected):hover {
            background: #00000017;
          }
        }

        @media (max-width: $breakpoint-sm) {
          gap: 7px;
          .MuiButtonBase-root {
            min-height: 41px;
            font-size: 14px;
            padding: 6px 9px;

            .MuiBadge-badge {
              font-size: 7px;
              height: 12.7px;
            }
          }
        }
        // .MuiButtonBase-root:hover { background-color: rgba(0, 0, 0, 0.04); }
      }
    }

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      color: white;

      .MuiBadge-badge {
        background-color: #ffffff;
      }
    }

    .MuiTabScrollButton-root {
      @media (max-width: $breakpoint-sm) {
        display: inline-flex;
      }
    }

    .MuiBadge-badge {
      position: static;
      -webkit-transform: scale(1) translate(50%, 0);
      -moz-transform: scale(1) translate(50%, 0);
      -ms-transform: scale(1) translate(50%, 0);
      transform: scale(1) translate(50%, 0);
      transform-origin: 100% 0%;
      padding: 5px;
      margin-left: -5px;
      color: #ffffff;
      @media (max-width: $breakpoint-sm) {
        display: none;
      }
    }

    .show_hidden_box {
      background: white;
      padding: 10px 8px;
      border-radius: 20px;

      .show_hidden.Mui-disabled {
        background-color: none;
      }

      .show_hidden {
        border-radius: 15px;
        text-transform: none;
        min-height: 41px;
        font-size: 14px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 500;
        gap: 4px;

        .visibility_text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 97px;
          @media (max-width: $breakpoint-lg) {
            display: none;
          }
        }
      }
    }

    .filter_button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 34px;
      background: #ffffff;
      padding: 10px 10px;

      @media (max-width: $breakpoint-ipad) {
        padding: 6px 7px;
      }

      .MuiButtonBase-root {
        border-radius: 49px;
        text-transform: capitalize;
        //width: 41px;
        height: 41px;

        .MuiButton-startIcon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          padding: 6%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .MuiButtonBase-root:hover {
        background: #00000017;
      }
    }

    .filter_button_active {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 34px;
      background: #ffffff;
      padding: 10px 10px;

      @media (max-width: $breakpoint-ipad) {
        padding: 6px 7px;
      }

      .MuiButtonBase-root {
        border-radius: 49px;
        text-transform: capitalize;
        //width: 41px;
        height: 41px;

        .MuiButton-startIcon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          padding: 6%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .MuiButtonBase-root:hover {
        background: #00000017;
      }
    }

    .course_overview_filters_grid {
      @media (max-width: $breakpoint-sm) {
        display: none;
      }

      .MuiButtonBase-root.MuiAccordionSummary-root {
        height: 41px;
        min-height: 41px;
        padding: 0px 10px;
        @media (max-width: $breakpoint-sm) {
          padding: 0px 8px;
        }
      }

      .MuiAccordion-root {
        box-shadow: none;
        padding: 10px 0px;
        border-radius: 20px;

        .MuiButtonBase-root-MuiAccordionSummary-root {
          margin: 0px;
        }

        .MuiAccordionDetails-root {
          padding: 0px;

          .MuiList-root {
            padding: 0px;
          }
        }

        .MuiAccordionSummary-expandIconWrapper {
          height: 41px;
          align-items: center;
          border-radius: 0px 12px 12px 0px;
        }
      }

      .MuiCollapse-root {
        position: absolute;
        width: 100%;
        background: white;
        border-radius: 11px;
        left: 0px;
        top: 54px;
        padding: 0px 16px;
        z-index: 1000;
      }

      .MuiPaper-root.Mui-expanded.MuiAccordion-root {
        border-radius: 20px 20px 0px 0px;
      }

      .Mui-expanded {
        .MuiAccordionSummary-expandIconWrapper {
          border-radius: 12px 0px 0px 12px;
        }
      }

      .MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
        background: none;
      }

      .MuiButtonGroup-root {
        box-shadow: none;
        min-height: 41px;
      }

      .MuiButton-root {
        height: 41px;
        border: none;
        padding: 0px;
        border-radius: 12px 0px 0px 12px;

        > span {
          display: flex;
        }
      }

      .MuiButtonBase-root-MuiButton-root:hover {
        background-color: transparent;
      }
    }
  }

  .course_overview_tabs.MuiBox-root {
    padding: 10px;
    @media (max-width: $breakpoint-sm) {
    }
  }

  .course_overview_filterbar2 {
    flex-grow: 1;

    @media (min-width: $breakpoint-ms) {
      width: calc(100% - 280px);
    }
    @media (max-width: $breakpoint-ms) {
      padding: 0px;
      overflow: hidden;
    }
  }

  .course_overview_filters {
    margin-top: 16px;
    margin-bottom: 16px;

    .MuiGrid-container {
      align-items: center;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    .course_overview_filters_stack {
      width: 75%;

      .MuiPaper-root-MuiAccordion-root {
        color: red;
      }

      @media (max-width: $breakpoint-ipad) {
        width: 100%;
      }
      @media (max-width: $breakpoint-ms) {
        display: block;
        .MuiFormControl-fullWidth {
          margin-left: 0px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .course_overview_box {
    display: flex;

    /* Start banner styling */
    .banner {
      margin: 16px;
      height: var(--height-gallery);
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fadeIn 1s ease-in-out;

      &.loading,
      &.error,
      &.fallback {
        border-radius: var(--border-radius);
      }

      &.fallback {
        > div {
          width: 100%;
          height: 100%;

          position: relative;
          z-index: 1;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: -1;
          border-radius: var(--border-radius);
        }
      }

      .gallery {
        --width-responsive: 256px;
        --padding-small: 8px;
        --padding-medium: 16px;
        --margin-small: 8px;
        --margin-medium: 16px;
        --gap: 16px;

        .children_box {
          background-position: center;
          background-size: cover;
          max-width: 100%;
          overflow: hidden;
          width: 100%;
          border-radius: var(--border-radius);
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          height: var(--height-gallery);
          position: relative;
        }

        .carousel-item {
          width: 100%;
          height: var(--height-gallery);
          position: absolute;
          left: 0;
          right: 0;

          > a {
            text-decoration: none;
          }

          .carousel_container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: inherit;
            padding: 0 5%;

            .MuiBox-root {
              white-space: break-spaces;
              .MuiButton-root {
                text-transform: none;
              }
            }

            .action_box {
              display: flex;
              flex-direction: row;
              align-self: flex-start;
              gap: var(--gap);
            }
          }

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
            border-radius: var(--border-radius);
          }

          h1,
          p,
          button {
            margin-top: var(--margin-medium);
          }

          h1 {
            font-size: 28px;

            @media (min-width: $breakpoint-ms) {
              font-size: 34px;
            }
          }

          p {
            font-size: 13px;
            margin-top: var(--margin-small);

            @media (min-width: $breakpoint-ms) {
              font-size: 16px;
              margin-top: var(--margin-medium);
            }
          }
        }

        .indicators_box {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 0 var(--margin-small) 0;
          width: auto;
          position: absolute;
          bottom: 0;

          .MuiButton-root {
            color: #fff;
            opacity: 0.6;
            min-width: auto;
            padding: 0;
            margin: 3px;

            .MuiSvgIcon-root {
              width: 14px;
              height: 14px;
              padding: 0;
            }
          }

          .active {
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
    /* End banner styling */

    .MuiGrid-container {
      margin-top: 0;
    }

    .MuiButton-root {
      align-self: flex-start;
    }

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      .margin-top {
        margin-top: 0px;
      }
    }

    .noFavorite_section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 39vh;
      gap: 46px;

      .favourite_icon {
        background-color: white;
        border-radius: 70px;
        height: 106px;
        width: 106px;

        .MuiSvgIcon-root {
          width: 3em;
          height: 2em;
        }
      }
    }

    .filteredResults {
      display: flex;
      flex-direction: row;
      gap: 1%;

      .MuiButton-root {
        background: rgba(255, 91, 91, 0.1);
        border-radius: 6.20446px;
        color: #ff5b5b;
        font-weight: 500;
        font-size: 12px;
        text-transform: capitalize;
      }
    }
  }

  .course_overview_grid {
    padding: 16px;
    @media (max-width: $breakpoint-ipad) {
    }

    .MuiGrid-root {
      &.MuiGrid-item {
        padding-top: 0px;
        padding-left: 20px;
      }
    }

    .MuiAvatar-root {
      width: 24px;
      height: 24px;
    }

    .MuiCard-root {
      width: -webkit-fill-available;
      width: -moz-available;
      border-radius: 20px;
      box-shadow: none;
      position: relative;
      cursor: pointer;
      height: 100%;
      justify-content: space-between;
      display: inline-flex;
      flex-direction: column;
      @media (max-width: $breakpoint-ms) {
        &:hover {
          .hideCourse {
            width: 100%;
          }
        }
      }

      .courseTitle {
        h1:only-child {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 46.8px;
        }

        h1 {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 23.8px;
        }
      }

      h1 {
        font-size: 18px;
      }

      .MuiCardMedia-root {
        border-radius: 20px 20px 0px 0px;
        // height: 194px;
      }

      .MuiCardContent-root {
        display: grid;
        gap: 4px;
        height: 130.4px;
        padding: 19px 16px 22px 16px;

        i {
          display: -webkit-box;
          max-width: 300px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .MuiCardActions-root {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px;

        .podcast_icon {
          border-radius: 70px;
          padding: 6px;
          // &:hover {
          //   background-color: #e27739;
          // }
          .MuiSvgIcon-root {
            height: 15px;
            width: 15px;
          }
        }
      }

      img {
        transition: ease all 0.75s;
        transform-origin: 50% 100%;
        height: 180px;
      }

      &:hover {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);

        img {
          transform: scale(110%);
          -webkit-transform: scale(1.1, 1.1);
          -moz-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
        }
      }

      .MuiChip-root {
        position: absolute;
        bottom: 0;
        border-radius: 0;

        .MuiChip-label {
          padding-right: 16px;
          font-size: 14px;
        }
      }

      .favourite_icon {
        position: absolute;
        top: 18px;
        right: 16px;
        background-color: white;
        border-radius: 70px;
        padding: 6px;

        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
        }
      }

      .like_icon {
        position: absolute;
        top: 18px;
        right: 52px;
        background-color: white;
        border-radius: 70px;
        padding: 6px;

        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
        }
      }

      .like_icon:hover {
        transform: scale(110%);
        -webkit-transform: scale(1.1, 1.1);
      }

      .favourite_icon:hover {
        transform: scale(110%);
        -webkit-transform: scale(1.1, 1.1);
      }

      .course_overview_card_accreditations {
        display: flex;

        .MuiAvatar-root {
          // margin-left: 2.5px;
          // margin-right: 2.5px;
          width: 30px;
          height: 30px;
          font-size: 14px;
          // border: 1px solid;
          &.chip {
            border: none;
          }
        }
      }

      .duration_icon {
        color: #fff;
        padding: 5px 16px;
        font-weight: 500;
        font-size: 14px;
        height: 34px;
        border-radius: 70px;
        display: flex;
        align-items: center;
      }

      .MuiButtonBase-root {
        font-size: 15px;
      }
    }

    .courseview_category_introduction {
      margin-bottom: 16px;
    }
  }

  // ======= .hidden
}

.filter_border {
  border: 1px solid var(--divider-color);
  border-radius: 4px;
  color: #405872;

  .MuiList-root {
    border-bottom: 1px solid #405872;
    width: 90%;
    left: 7%;
  }

  .MuiList-root:last-child {
    border-bottom: none;
    width: 100%;
    left: 0;
  }
}

.intro_full {
  background: #fff;
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 16px;
}

.top_links {
  text-align: left;
  padding-left: 26px;
  display: flex;

  a {
    margin-right: 16px;
    font-size: 14px;
    font-weight: 700;
    color: #2b3674;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    text-transform: none;

    &:hover {
      color: #000;
    }

    &.active {
      background-color: rgba(0, 104, 70, 0.04);
    }
  }
}
