/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.mobile-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  border-radius: 34px;
  .mobile_logo_box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    .MuiButtonBase-root {
      background: #f4f7fe;
      border-radius: 11px;
      width: 34px;
      height: 34px;
      color: #2b3674;
    }
  }
  .mobile_header_icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    @media (min-width: $breakpoint-ms) {
      padding: 10px 10px;
    }
    // @media (max-width: $breakpoint-sm) {
    //   .MuiChip-root {
    //     width:40%;
    //   }
    // }
    .product_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90px;
      display: block;
    }
    .MuiPopover-paper {
      background-color: red;
    }
    .MuiButtonBase-root {
      background: #f4f7fe;
      border-radius: 11px;
      width: 34px;
      height: 34px;
      color: #2b3674;
      .MuiAvatar-root {
        border-radius: 11px;
        width: 30px;
        height: 30px;
      }
    }
    #go-to-assistant,
    #go-to-lms {
      width: auto;
    }
    .MuiChip-root {
      height: 34px;
      border-radius: 11px;
    }
    .MuiButtonBase-root.MuiIconButton-root:hover {
      background: unset;
    }
  }
}
