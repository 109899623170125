.custom-onboarding {
  .introjs-tooltip-header {
    padding: 20px 20px 0 20px;
  }
  .introjs-tooltip-title {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    letter-spacing: 0;
    color: #2b3674; // Should be themed
    display: none;
  }

  .introjs-tooltiptext {
    margin-top: 30px;
    padding-top: 0;
    font-size: 18px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height: 1.3;
  }

  .introjs-tooltipbuttons {
    border-top: none;
    padding: 12px 20px 20px;

    .introjs-button {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      -moz-appearance: none;
      font-size: 14px;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 500;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 6px 16px;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: #fff;
      background-color: #006846;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 5px 0px rgba(0, 0, 0, 0.12);
      box-sizing: border-box;
      position: relative;
      overflow: visible;
      border: none;
      text-decoration: none;
      text-shadow: none;
      white-space: nowrap;
      outline: 0;

      &.introjs-prevbutton {
        background-color: transparent;
        box-shadow: none;
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
