/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.grid_swipable {
  width: 100%;
  @media (min-width: $breakpoint-ms) {
    display: none;
  }
  .carousel {
    display: flex;
    flex-direction: row;
    position: relative;
    .indicators {
      top: 50%;
      transform: translateY(-50%);
    }
    .indicatorsLeft {
      left: 4px;
      right: unset;
    }
    .indicatorsRight {
      left: unset;
      right: 4px;
    }

    @media (max-width: $breakpoint-sm) {
      width: 100%;
      .indicatorsLeft {
        left: 8px;
      }
    }

    .MuiButton-root {
      align-self: center;
    }

    .dragable {
      overflow: hidden;
      width: 100%;
      .inner {
        display: flex;
        gap: 9px;
        padding-block: 10px;
      }

      @media (max-width: $breakpoint-sm) {
        width: 100%;
        .inner {
          display: flex;
          gap: 9px;
        }
      }
    }
  }
  .carousel-item {
    //width: 560px;
    .category {
      margin-bottom: 10px;
      .MuiTypography-root {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 560px;
      }
    }

    .course_overview_card {
      width: 362px;
      // @media (max-width: $breakpoint-sm) {
      //   width: 200px;
      // }
      @media (max-width: $breakpoint-sm) {
        width: 250px;
      }
    }
  }

  .active {
    @media (max-width: $breakpoint-sm) {
      margin: 0px;
    }
  }
}
