$mainbackground-color: #EEF2FA;

.advanced__screen_dialog {
  .advanced_select_courses {
    .admin-custom-page {
      display: flex;
      flex-direction: column;
      .advanced-course-tables {
        >.MuiPaper-root {
          display: flex;
          flex-direction: column;
          flex: 1;
          >table { display: flex; flex-direction: column; margin-top: auto; }
        }

      }
    }

  }

  .advanced-course-tables {
    display: flex;
    flex-direction: column;
    flex: 1;
    h6 {
      font-size: 26px;
      font-weight: 400;
    }
    .MuiPaper-root {
      box-shadow: none;
    }
    table {
      thead {
        th {
          background: #57758345;
          border: 3px solid white;
        }
      }
      tbody {
        font-size: 14px;
      }
    }
    
  }
  .advanced_course_action {
    li {
      &.isActive {
        // background: primary!important;
        color: white;
      }
    }
  }

}

// TODO: ELISE
.admin__ewapi_tables {
  .test {display: none} // fake attribute otherwise it shows a lint error
  [class*="Component-horizontal"] {
    background: white!important;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .MuiPaper-root {
    &> div:nth-of-type(2) {
      padding-top: 10px;
      border-radius: 10px;
      table {
        thead {
          text-transform: uppercase;
          tr {
            th {width: auto!important;}
          }
        }
        tbody {
          font-size: 15px;
          tr:hover { background: #3f51b50f }
          tr {
            td {width: auto!important}
          }
        }
      }
    }
  }
  
}
.admin-course-layout {
  .mtoolbar__container {
    .MuiToolbar-regular {
      border-radius: 40px;
      background: white;
      padding: 0px 10px;
      h6 { font-size: 30px; font-weight: 400; border-radius: 40px; padding: 0px 20px; }
      .MuiTextField-root {
        .MuiInputBase-root { height: 40px; border: none; border-radius: 40px; background: #eef2fa!important; }
      }
    }
  }
  tbody tr:nth-child(even) { background: #ffffff63; }
}

.admin-tables {
  .points {
    display: block;
    border: 1px solid #016d4a40;
    width: 40px;
    height: 40px;
    border-radius: 50%!important;
    line-height: 40px;
    font-size: 13px;
    text-align: center;
    color: #016d4a;
    font-weight: bold;
    transition: all .3s;
    &:hover {
      transform: scale(1.3);
      transition: all .3s;
    }
  }
  .MuiPaper-root {
    border-radius: 8px;
    padding: 10px;
    overflow: hidden;
    background: transparent;
    box-shadow: none;
     &> div:nth-of-type(1){
      .MuiFormControl-root {
        .MuiInputBase-root{
          border: 1px solid rgba(145, 158, 171, 0.32);
          background: white;
          &:before { border-bottom: none; }
          &:after { border-bottom: none; }
          .MuiInput-input { height: 2em; }
        }
      }
    }
     &> div:nth-of-type(2){ background: transparent; margin-top: 10px; }
     div[class^="Component-horizontalScrollContainer"] {
      table {
        thead {
          tr {height: 60px;}
          text-transform: uppercase;
        }
        tbody {
          font-size: 15px;
          &> tr:nth-of-type(1){
             
            .MuiSelect-select {
              padding-left: 10px;
            }
            .MuiInput-root {
               &:before { border-bottom: none; }
                &:after { border-bottom: none; }
            }
            .MuiInputBase-root{
              background: white;
              border: 1px solid rgba(145, 158, 171, 0.32);
              .MuiInputBase-input {
                padding: 10px;
                &:before { border-bottom: none; }
                &:after { border-bottom: none; }
              }
            }

          }
          tr:hover {background: #3f51b50f}
           td {
              &:last-child {
                div {flex-direction: row; }
              }
            }
        }
      }
    }
    >table {
      .MuiTableCell-footer {
        border-bottom: none;
        .MuiTablePagination-toolbar {
          border-radius: 10px;
          background: white;
        }
      }
    }
  }

}
