$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.curriculum_layout {
  .navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.75rem;
    align-items: center;
    box-shadow: none;
    @media (min-width: $breakpoint-ms) {
      box-shadow: 0px 11.168px 24.8178px rgba(112, 144, 176, 0.12);
      align-items: stretch;
      padding: 1.5rem;
      background-color: #fff;
    }

    .tab {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      overflow: hidden;

      .tab_label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        width: 100%;
      }

      .MuiTabs-flexContainer {
        gap: 14px;
      }

      .MuiBadge-root {
        margin-left: 10px;
      }

      .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: white;

        .MuiBadge-badge {
          background-color: #ffffff;
        }
      }

      .MuiButtonBase-root.MuiTab-root {
        align-items: flex-start;
        border: 1px solid #eeeeee;
        border-radius: 17.2584px;
        text-transform: none;
        color: #2b3674;
        font-weight: 700;

        .MuiSvgIcon-root {
          width: 14px;
          height: 14px;
        }
      }

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }
      }

      @media (min-width: $breakpoint-ms) {
        flex-direction: column;
      }
    }
  }

  .open {
    background-color: transparent;
    box-shadow: none;
  }

  .info_box {
    display: flex;
    padding: 0.75rem;
    background-color: #ffffff;
    align-items: flex-end;
    border-radius: 0.75rem;
    column-gap: 0.5rem;

    .info_buttons {
      border-radius: 12.9401px;
      box-shadow: 0px 11.6461px 25.8802px rgba(112, 144, 176, 0.12);
      text-transform: capitalize;
    }
  }


  .MuiAccordionSummary-root.Mui-expanded,
  .css-1fulrj6-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
}
