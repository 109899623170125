/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.carousel {
  overflow: hidden;
  //position: relative;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
    display: table;
    content: '';
  }
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.active {
  display: inline-flex;
}

.indicators {
  // display: flex;
  // justify-content: center;
  // color: #fff;
  position: absolute;
  z-index: 1;
  top: 47%;
  left: -2%;
  .MuiButton-root {
    background: #f4f7fe;
    height: 37px;
    width: 37px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.22);
    border-radius: 50%;
    min-width: 37px;
  }
}

.indicatorPoints {
  display: flex;
  align-items: center;
  justify-content: center;
}
.indicatorPoints > button {
  .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
  }
}

.indicatorPoints > button.active {
  color: #fff;
}

.indicatorsRight {
  left: 98%;
}

.indicators > button {
  .MuiSvgIcon-root {
    width: 27px;
    height: 27px;
  }
}

.indicators > button.active {
  color: #fff;
}
