/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.account {
  display: flex;

  .account_sidebar {
    box-shadow: none;
    border: none;
    border-radius: 17.2px;
    height: 370px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 30px;

    @media (max-width: $breakpoint-sm) {
      padding: 0px;
    }

    .MuiTabs-flexContainer {
      gap: 14px;
    }

    .tab_label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
      color: white;
      //box-shadow: 0px 20px 40px -10px rgba(112, 144, 176, 0.42);
    }

    .MuiButtonBase-root.MuiTab-root {
      align-items: flex-start;
      border: 1px solid #eeeeee;
      border-radius: 17.2584px;
      text-transform: none;
      color: #2b3674;
      font-weight: 700;

      .MuiSvgIcon-root {
        width: 14px;
        height: 14px;
      }

      .img {
        width: 14px;
        height: 14px;
      }

      &:hover:not(.Mui-selected) {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    .MuiTabs-scroller {
      .MuiTabs-indicator {
        display: none;
      }
    }
  }

  //Profile
  .profile_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f4f7fe;
  }

  .profileBadge img {
    cursor: pointer;
  }

  .profileBadge:hover {
    transform: scale(1.2);
    cursor: pointer;
    opacity: 0.8;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
  }

  .profile_box {
    background: white;
    border-radius: 18px;

    .MuiGrid-root {
      margin-top: 0px;
      padding-top: 0px;
      align-items: center;
    }

    .image_upload {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .profile_form {
      .MuiOutlinedInput-root {
        background: #fafafa;
        border-radius: 10px;
        color: #2b3674;

        .MuiOutlinedInput-notchedOutline {
          border-color: #fafafa;
        }

        .MuiInputBase-input {
          padding: 8.5px 14px;
        }

        .MuiInputBase-input.Mui-disabled {
          -webkit-text-fill-color: #404040;
          cursor: not-allowed;
        }
      }

      .MuiInputLabel-root.Mui-disabled {
        color: #404040;
      }

      .profile_select {
        .css-1nrlq1o-MuiFormControl-root {
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }

      .profile_datepicker {
        .MuiFormLabel-root.MuiInputLabel-root {
          top: -6px;
        }
      }

      .checkbox_style {
        .MuiTypography-root {
          font-size: 12px;
          color: #404040;
        }
      }
    }
  }
}

.reset_pass {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*input has OS specific font-family*/
  color: #2b3674;
  text-decoration: underline;
  text-transform: initial;
  cursor: pointer;
}

.resetForm {
  .MuiDialogTitle-root {
    font-size: 20px;
    text-align: center;
  }

  .MuiOutlinedInput-root {
    background: #fafafa;
    border-radius: 10px;
    color: #2b3674;

    .MuiOutlinedInput-notchedOutline {
      border-color: #fafafa;
    }

    .MuiInputBase-input {
      padding: 8.5px 14px;

      .Mui-disabled {
        color: #2b3674;
      }
    }
  }

  @media (max-width: $breakpoint-ms) {
    .MuiGrid-root {
      padding: 16px;
    }
  }
}

.account_sidebar_open {
  flex-direction: column;

  .MuiGrid-item {
    max-width: 100%;
  }

  .account_sidebar {
    background-color: transparent;
    height: fit-content;
    padding: 0px;

    .MuiTypography-root {
      display: none;
    }
  }
}

//Certificate
.account_certificate {
  border: none;

  .certificate_card {
    box-shadow: 0px 15.5326px 34.5169px rgba(112, 144, 176, 0.12);
    border-radius: 17.2584px;
    // height: 69px;
    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }

    .MuiGrid-root {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .download_button {
      @media (max-width: $breakpoint-sm) {
        margin-top: 8px;
      }
    }
  }

  .certificate_course {
    display: flex;
    flex-direction: column;

    .title {
      display: none;
      @media (max-width: $breakpoint-sm) {
        display: flex;
        gap: 4px;
        font-size: 10px;
        .MuiTypography-root {
          font-size: 10px;
        }
      }
    }

    .typography_box {
      display: none;
    }

    @media (max-width: $breakpoint-ipad) {
      .typography_box {
        display: flex;
        align-items: center;
        font-size: 10px;
        margin-bottom: 12px;

        .MuiTypography-root {
          font-size: 10px;
        }
      }
    }
    @media (max-width: $breakpoint-sm) {
      .title {
        display: flex;
      }
      margin-bottom: 12px;
    }
  }

  .certificate_button {
    flex-direction: row;
    display: flex;
    gap: 71px;
    justify-content: flex-end;
    align-items: center;

    .download_button {
      text-transform: capitalize;
      border-radius: 15px;

      .MuiButton-endIcon {
        margin-top: 4px;
      }
    }

    @media (max-width: $breakpoint-ipad) {
      .typography_box {
        font-size: 10px;

        .MuiTypography-root {
          font-size: 10px;
        }
      }
    }
    @media (max-width: $breakpoint-sm) {
      justify-content: center;
      .typography_box {
        display: none;
      }
    }
  }

  .certificate_duration {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    @media (max-width: $breakpoint-ipad) {
      display: none;
    }
  }

  .typography_box {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.certificate_select {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .MuiFormControl-root {
    width: 96px;

    .MuiFormLabel-root {
      top: -7px;
    }

    .MuiInputBase-root {
      border-radius: 17px;
      height: 41px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #eeeeee;
    }
  }
}

//Subscription
.account_subscriptions {
  border: none;
  height: auto;

  .subscription_card {
    box-shadow: 0px 15.5326px 34.5169px rgba(112, 144, 176, 0.12);
    border-radius: 17.2584px;

    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }

    .MuiGrid-root {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .typography_box {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    @media (max-width: $breakpoint-sm) {
      justify-content: start;
    }
  }

  .subscription_assistent {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-right: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    @media (max-width: $breakpoint-sm) {
      border: none;
      justify-content: flex-start;
    }
  }

  .certificate_select {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .MuiFormControl-root {
      width: 138px;
      @media (max-width: $breakpoint-sm) {
        width: 94px;
      }

      .MuiFormLabel-root {
        top: -7px;
      }

      .MuiInputBase-root {
        border-radius: 17px;
        height: 41px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #eeeeee;
      }
    }
  }
}

.account_loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
