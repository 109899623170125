/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.assistant_dashboard {

  .navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.75rem;
    align-items: center;
    box-shadow: none;
    @media (min-width: $breakpoint-ms) {
      box-shadow: 0px 11.168px 24.8178px rgba(112, 144, 176, 0.12);
      align-items: stretch;
      padding: 1.5rem;
      background-color: #fff;
    }

    .tab {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      overflow: hidden;

      .tab_label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        width: 100%;
      }

      .MuiTabs-flexContainer {
        gap: 14px;
      }

      .MuiBadge-root {
        margin-left: 10px;
      }

      .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: white;

        .MuiBadge-badge {
          background-color: #ffffff;
        }
      }

      .MuiButtonBase-root.MuiTab-root {
        align-items: flex-start;
        border: 1px solid #eeeeee;
        border-radius: 17.2584px;
        text-transform: none;
        color: #2b3674;
        font-weight: 700;

        .MuiSvgIcon-root {
          width: 14px;
          height: 14px;
        }
      }

      .MuiTabs-scroller {
        .MuiTabs-indicator {
          display: none;
        }
      }

      @media (min-width: $breakpoint-ms) {
        flex-direction: column;
      }
    }
  }

  .open {
    background-color: transparent;
    box-shadow: none;
  }

  .student_info_cards {
    display: flex;
    flex-direction: column-reverse;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 12px;
    @media (min-width: $breakpoint-ms) {
      flex-direction: row;
      row-gap: 1rem;
      align-items: center;
    }

    .cards {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      width: 100%;
      @media (min-width: $breakpoint-ms) {
        width: auto;
      }

      .student_card {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 8px 16px;
        border-radius: 12px;
        gap: 0.625rem;
        @media (min-width: $breakpoint-ms) {
          gap: 1rem;
        }

        .MuiSvgIcon-root {
          width: 18px;
          height: 22px;
        }

        .inner_box {
          padding: 0.357rem 0.5rem;
          border-radius: 0.25rem;
          box-shadow: 0px 10px 25px 0px #7090b01f;
          @media (min-width: $breakpoint-ms) {
            padding: 1rem 1.25rem;
            border-radius: 0.5rem;
          }
        }
      }
    }

    .card_buttons {
      display: flex;
      align-items: flex-end;
      gap: 0.5rem;

      .invite_button {
        border-radius: 0.75rem;
        text-transform: none;
        font-size: 0.75rem;
        line-height: 1rem;
        height: 44px;
      }

      .info_buttons {
        border-radius: 0.75rem;
        height: 44px;
        width: 44px;
      }
    }
  }

  .teacherSearch {
    @media (min-width: $breakpoint-ipad) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

.student_details {
  .header_cards {
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    gap: 0.75rem;
    @media (min-width: $breakpoint-ms) {
      flex-direction: row;
      gap: 1.25rem;
    }

    .card_layout {
      display: flex;
      background-color: #ffffff;
      flex-direction: row;
      gap: 0.75rem;
      flex: 1 1 0;
      @media (min-width: $breakpoint-ms) {
        gap: 1.25rem;
      }

      .personal_card {
        display: flex;
        flex-direction: row;
        gap: 0.375rem;
        flex: 1 1 0;
        border-radius: 0.5rem;
        padding: 0.75rem;
        box-shadow: 0px 10px 25px 0px #7090b01f;
        flex-wrap: wrap;
        @media (min-width: $breakpoint-ms) {
          flex-direction: column;
          gap: 0.25rem;
          padding: 1rem;

        }

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
        }
      }

      .info_card {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        flex: 1 1 0;
        border-radius: 0.5rem;
        padding: 0.75rem;
        box-shadow: 0px 10px 25px 0px #7090b01f;
        @media (min-width: $breakpoint-ms) {
          padding: 1rem;
        }
      }

      .score_card {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        border-radius: 0.5rem;
        padding: 0.75rem;
        box-shadow: 0px 10px 25px 0px #7090b01f;
        @media (min-width: $breakpoint-ms) {
          padding: 1rem;
        }

        .points {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5rem;
          height: 4rem;
          border-radius: 0.5rem;
          background: #2b36741a;
        }
      }
    }
  }
}

.invite_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border-radius: 10px;
  padding: 32px;
  background-color: #fff;

  .close_button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    padding: 0;
    width: 1.5rem;
    min-width: 0;
    height: 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.course_detail {
  .course_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      width: 100%;
      align-items: flex-end;
    }
  }

  .description_card {
    min-height: 6rem;
    padding: 0.75rem;
    height: 110px;
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    flex: 1 1 0%;
    border-radius: 0.5rem;
    gap: 0.25rem;
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      height: 100%;

      svg {
        width: 1em;
        height: 1em;
      }

      .MuiTypography-root {
        font-size: 12px;
        line-height: 1.25rem;
        @media (min-width: $breakpoint-lg) {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0%;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    height: 110px;

    @media (min-width: $breakpoint-ms) {
      flex-direction: row;
      gap: 16px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }

    .statistic_box {
      box-shadow: 0px 10px 25px 0px #7090b01f;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-inline: 24px;
      padding-block: 16px;
      height: 56px;
      width: 64px;
      background: #2B36741A;
      border-radius: 8px;

    }

    .MuiTypography-root {
      font-size: 12px;
      line-height: 1.25rem;
      @media (min-width: $breakpoint-lg) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  .info_buttons {
    border-radius: 12.9401px;
  }
}
