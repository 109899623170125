@import '../config';

.main_layout {
  display: flex;
  overflow-x: hidden;
}

.main_layout_children {
  width: calc(100% - 280px);
  flex-grow: 1;
  min-height: 100vh;
  padding: 16px;
  background: $background;
  transition: margin-left 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.main_paper {
  background: $background none;
  box-shadow: none;
  border-radius: 0;
}

.course_overview_headline {
  position: fixed;
  background: $background none;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0;
  left: auto;
  right: 0;
  backdrop-filter: blur(6px);
  box-shadow: none;
  z-index: 1101;
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: calc(100% - 280px);
  height: $header_height_desktop;
  padding: 0 32px;

  .min_drawer & {
    width: calc(100% - 97px);
  }

  .no_sidebar & {
    width: 100%;
  }

  .is_mobile & {
    height: $header_height_mobile;
    padding: 0;
    width: 100%;
  }
}

.course_overview_headline + .main_content {
  margin-top: $header_height_desktop;

  .is_mobile & {
    margin-top: calc(#{$header_height_mobile} - 16px);
  }
}

.main_layout_children_white {
  &,
  .main_paper,
  .course_overview_headline,
  .course_overview_headline_max,
  .course_overview_mobile,
  .mobile_headline,
  .course_overview_headline_title,
  .course_overview_headline_stack {
    background: #ffffff;
  }

  .course_overview_headline {
    @media (max-width: $breakpoint-ms) {
      padding: 0;
      left: 0;
    }
  }

  .course_overview_headline_icons {
    border-radius: 34px;
  }
}

.course_overview_headline_stack {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: $background;
  padding: 16px 0;

  .is_mobile & {
    flex-wrap: wrap;
    background: #fff;
    padding: 16px 16px 0;
  }
}

.course_overview_headline_hamburger {
  flex: none;
  order: 1;
}

.course_overview_headline_mobile_logo {
  flex: none;
  order: 2;
}

.course_overview_headline_title {
  flex: 1.5;
  max-width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  order: 3;

  .is_mobile & {
    flex: 1 0 100%;
    order: 4;
    padding: 32px;
    margin: 0 -16px;
    background: $background;
  }

  h1 {
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $breakpoint-ms) {
      font-size: 19px;
    }
  }

  h2 {
    font-size: 14px;
    color: #707eae;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $breakpoint-ms) {
      font-size: 12px;
    }
  }
}

.course_overview_headline_menu {
  flex: 1;
  order: 4;
  display: flex;
  justify-content: flex-end;

  .is_mobile & {
    order: 3;
  }
}

.course_overview_headline_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  .is_mobile &,
  .no_sidebar & {
    justify-content: right;
    flex: 1 0 auto;
  }

  .course_overview_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;
    background: #ffffff;
    padding: 10px 10px;

    @media (max-width: $breakpoint-ms) {
      padding: 0;
    }

    .course_overview_notifications {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      @media (max-width: $breakpoint-ms) {
        gap: 5px;
      }

      .product_title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 122px;
        display: block;
      }

      .MuiChip-root {
        height: 41px;
      }

      .product_menu {
        align-self: center;
        width: auto;
        line-height: 15px;
      }
    }
  }

  .MuiInputSearch {
    height: 41px;
    font-size: 14px;
    margin: 0 11px 0 0;

    .MuiInputBase-input {
      height: 15px;
      padding-left: calc(1em + 20px);
    }

    .css-1iz4iln {
      padding: 0px 8px;
    }

    @media (max-width: $breakpoint-ipad) {
      .MuiSvgIcon-root {
        width: 15px;
        height: 15px;
      }
    }
  }

  @media (max-width: $breakpoint-ipad) {
    justify-content: flex-start;
  }
  .MobileSearchButton {
    display: none;
  }
  @media (max-width: $breakpoint-ipad) {
    .MobileSearchButton {
      display: block;
      svg {
        display: block;
        margin: 0 auto;
      }
    }
    .MuiInputSearch {
      display: none;
    }
  }
}
