/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.scorePage {
    .scorePageCard {
        width: 100%;
        max-width: 40%;
        @media (max-width: $breakpoint-ms) {
            max-width: 98%;
        }
    }

}