/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

@mixin minimize-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: white;
  color: #2b3674;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  left: 258px;
  z-index: 15454;
  top: 27px;
}

.right_sidebar {
  width: 280px;
  flex-shrink: 0;
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @media (max-width: $breakpoint-ms) {
    width: 0px;
  }
  .MuiDrawer-paper {
    box-sizing: border-box;
    border: none;
    width: 280px;
    overflow-x: hidden;
    height: 100vh;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* hide scrollbar on Firefox */
    scrollbar-width: none;
    /* hide scrollbar on Edge */
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .minimize_button {
    @include minimize-button;
  }
  .main_box {
    height: inherit;
    .drawer_footer_box {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 90px);
    }
    .drawer_second_box {
      display: flex;
      flex-direction: column;
      height: 100%;
      #logo {
        width: 100px;
      }
    }
  }
  .header_logo {
    padding-bottom: 0px;
    border-bottom: 2px solid #eef2fa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    .logo-wrapper {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      .logo {
        max-width: 75px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .package_list {
    padding: 20px 25px;

    .MuiList-root {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .MuiButtonBase-root.MuiListItemButton-root:hover {
        border-radius: 15px;
      }
      .lists {
        display: flex;
        align-items: center;
        gap: 15px;
        .abr {
          display: none;
        }
      }

      .MuiListItemText-root {
        margin-top: 1px;
        margin-bottom: 1px;
        transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        .MuiTypography-root {
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          color: #2b3674;
        }
      }
      .Mui-selected {
        border-radius: 15px;
        box-shadow: 0px 20px 40px -10px rgba(112, 144, 176, 0.42);
        .MuiTypography-root {
          color: white;
        }
        .tabLabel {
          color: white;
          .abr {
            display: none;
          }
        }
      }
    }
    .color {
      stroke: black;
    }

    .tabLabel {
      display: flex;
      align-items: center;
      gap: 15px;
    }
    .tabs_badge {
      position: absolute;
      right: 13px;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      display: block;
      line-height: 25px;
      color: #000;
      font-size: 10px;
      background-color: white;
    }
  }
  .my_company {
    padding: 0px 25px;
    .MuiList-root {
      transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      .MuiButtonBase-root.MuiListItemButton-root:hover {
        border-radius: 15px;
      }
      .MuiTypography-root {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        color: #2b3674;
      }
      .Mui-selected {
        background-color: unset;
      }
      .MuiButtonBase-root {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
    .color {
      stroke: black;
    }
  }
  .right_side_footer {
    padding: 25px 25px;
    margin-top: auto;
    #img {
      padding-bottom: 20px;
    }
    #subscribe {
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    #subscribeXs {
      display: none;
    }
  }
  .right_side_footer.alert {
    background: linear-gradient(45deg, #ffd700, #ffa500);
    .MuiButtonBase-root {
      background-color: #000;
    }
  }
}

.right_sidebar_minimized {
  width: 97px;
  flex-shrink: 0;
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  @media (max-width: $breakpoint-ms) {
    width: 0px;
  }
  .minimize_button {
    @include minimize-button;
    display: none;
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .MuiDrawer-paper {
    width: 97px;
    overflow-x: hidden;
    height: 100vh;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* hide scrollbar on Firefox */
    scrollbar-width: none;
    /* hide scrollbar on Edge */
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    // MENU ITEMS
    .minimize_button {
      display: none;
    }
    .main_box {
      height: 94vh;
      .drawer_footer_box {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .drawer_second_box {
        height: 100%;
        display: flex;
        flex-direction: column;
        #logo {
          width: 75px;
        }
      }
    }
    .header_logo {
      display: flex;
      height: 90px;
      border-bottom: 2px solid #eef2fa;
      .logo-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        width: 100%;
        .logo {
          width: 75px;
        }
      }
    }
    .package-item {
      &:not(.Mui-selected) {
        background: #0068480d;
      }
    }
    .package_list {
      padding: 20px 25px;
      // border-bottom: 2px solid #eef2fa;
      .MuiList-root {
        transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .MuiButtonBase-root.MuiListItemButton-root:not(.Mui-selected):hover {
          border-radius: 15px;
          background-color: rgba(0, 0, 0, 0.04);
        }
        .MuiListItemButton-root {
          padding-left: 11px;
          border-radius: 15px;
          .lists {
            display: flex;
            align-items: center;
            gap: 15px;
            color: #2b3674;
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
            font-weight: bold;
            justify-content: center;
            .MuiSvgIcon-root {
              color: #2b3674;
            }
            div {
              display: none;
            }
          }
        }
        .Mui-selected {
          border-radius: 15px;
          box-shadow: 0px 20px 40px -10px rgba(112, 144, 176, 0.42);
          color: white;
          .lists {
            color: white;
          }
          .tabLabel {
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            div {
              display: none;
            }
          }
        }
      }
      .tabLabel {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
    .my_company {
      padding: 0px 25px;
      p {
        display: none;
      }
      .MuiListItemText-root {
        color: white;
      }
      .Mui-selected {
        background-color: unset;
      }
    }
    .right_side_footer {
      padding: 25px 25px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      height: 100%;

      #subscribe {
        display: none;
      }
      #subscribeXs {
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
    .right_side_footer.alert {
      display: none;
    }
    // ON HOVER
    &:hover {
      width: 280px;
      height: 100vh;
      .minimize_button {
        display: block;
        // animation: fadeIn 1s;
      }
      .header_logo {
        height: 90px;
        display: flex;
        .logo-wrapper {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 100%;
          .logo {
            max-width: 75px;
          }
        }
      }
      .minimize_button {
        @include minimize-button;
        transform: rotate(90deg);
        transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
      .package-item {
        &:not(.Mui-selected) {
          background: transparent;
        }
      }

      .package_list {
        border-bottom: none;
        .tabLabel {
          display: flex;
          align-items: center;
          gap: 15px;
          text-transform: capitalize;
          .abr {
            display: none;
          }
          div {
            display: flex;
          }
        }
        .MuiList-root {
          display: flex;
          flex-direction: column;
          gap: 16px;
          .Mui-selected {
            div {
              display: flex;
              text-transform: capitalize;
              // color: white;
            }
          }
          .MuiListItemButton-root {
            padding-left: 16px;
            .MuiListItemText-root {
              margin-top: 1px;
              margin-bottom: 1px;
            }

            .MuiTypography-root {
              font-weight: 500;
              font-size: 16px;
              line-height: 30px;
              color: #2b3674;
            }
            .tabs_badge {
              position: absolute;
              right: 13px;
              border-radius: 50%;
              width: 8px;
              height: 8px;
              display: block;
              line-height: 25px;
              color: #000;
              font-size: 10px;
              background-color: white;
            }
            .lists {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 15px;
              // color: #2b3674;
              font-weight: 500;
              font-size: 16px;
              line-height: 30px;
              text-transform: capitalize;
              .abr {
                display: none;
              }
              div {
                display: flex;
              }
            }
          }
        }
      }
      .my_company {
        margin-top: 16px;
        p {
          display: flex;
        }
        p.MuiTypography-root {
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          color: #2b3674;
        }
        .MuiTypography-root {
          font-weight: 500;
          font-size: 16px;
          line-height: 30px;
          color: #2b3674;
        }
      }
      .drawer_second_box {
        #logo {
          width: 100px;
        }
      }
      .right_side_footer {
        padding: 25px 25px;
        #img {
          padding-bottom: 20px;
        }
        #subscribe {
          transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          display: flex;
        }
        #subscribeXs {
          display: none;
        }
      }
    }
  }
}
