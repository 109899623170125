.product-selector {
  margin-top: 100px;
  h1 {
    text-align: center;
  }
  .user-name {
    display: flex;
    justify-content: center;
  }
  .product-select {
    width: 50%;
    min-width: 300px;
    margin: auto auto;
  }
  .prod-select-box {
    background: white;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 1px 3px 15px #00000024;
    .product-select-header {
      padding: 15px 0px;
      display: flex;
      justify-content: center;
    }
  }
  .acc {
    box-shadow: none;
    background: #9e9e9e0a;
    border-radius: 20px;
    margin: 20px 5px;
    transition: background 0.5s;
    -webkit-transition: background 0.5s;
    -moz-transition: background 0.5s;
    -ms-transition: background 0.5s;
    -o-transition: background 0.5s;
    &:hover {
      background: #9e9e9e21;
    }
    &:last-of-type {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    &:before {
      background: transparent;
    }
    // HEADER
    .acc-header {
      padding: 7px 16px;
      &.Mui-expanded p {
        font-weight: bold;
      }
      &.acc-header-selected {
        background: #9e9e9e21;
        border-radius: 20px;
      }
    }
    // DETAIL
    .acc-detail {
      .acc-detail-item {
        transition: all 0.5s;
        margin: 10px 0px;
        padding: 15px 10px;
        &:hover,
        &.acc-detail-selected {
          box-shadow: 1px 2px 5px #00000033;
          border-radius: 15px;
          background: white;
          transition: background 0.5s;
          cursor: pointer;
          font-weight: normal;
        }
      }
    }
  }
}
