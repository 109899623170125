/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.filterBar {
  background-color: white;
  width: 371px;
  max-width: calc(100vw - 32px);
  border-radius: 12.4089px;
  height: 60%;
  overflow-y: auto;
  margin-top: 16px;
  margin-right: 16px;
  box-shadow: 0px 12.4089px 24.8178px rgba(112, 144, 176, 0.25);
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: fixed;
  right: 0;
  max-height: 90%;
  @media (max-width: $breakpoint-xl) {
    max-height: 70%;
  }
  .filterBar_closeIcon {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    .MuiButtonBase-root {
      background: #f4f7fe;
      border-radius: 49px;
      color: #2b3674;
      width: 25px;
      height: 25px;
    }
    @media (min-width: $breakpoint-lg) {
      display: none;
    }
  }
  .authorSection {
    width: 100%;
    margin-bottom: 1rem;
    .teacherButton {
      font-size: 12px;
      width: 100%;
      text-transform: capitalize;
      height: 35px;
      align-self: center;
    }
  }
  .filters_headline {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .MuiButton-root {
      background: rgba(255, 91, 91, 0.1);
      border-radius: 6.20446px;
      color: #ff5b5b;
      font-weight: 500;
      font-size: 12px;
      text-transform: capitalize;
    }
  }
  .filter_search_author {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
    background: white;
    border-radius: 12.4089px;
    box-shadow: 0px 11.168px 24.8178px rgba(112, 144, 176, 0.12);
    padding: 0px 7px;
    .MuiAutocomplete-root {
      width: 100%;
      .MuiFormControl-root {
        .MuiInputLabel-root.MuiFormLabel-root {
          color: #8f9bba;
          font-size: 14px;
        }
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .MuiAutocomplete-clearIndicator {
      .MuiSvgIcon-root {
        width: 14px;
        height: 17px;
        margin-left: 11px;
        color: #2b3674;
      }
    }
    .MuiAutocomplete-clearIndicator:hover {
      background-color: transparent;
    }
  }

  .course_overview_filters_stack1 {
    width: 100%;
    .MuiPaper-root.MuiAccordion-root {
      border-top: 1px solid var(--divider-color);
      box-shadow: 0px 11.168px 24.8178px rgba(112, 144, 176, 0.12);
      border-radius: 12.4089px;
      .MuiAccordionDetails-root {
        padding: 0px 16px 16px;
        .MuiGrid-root{
          flex-wrap: nowrap;
        }
        .MuiListItemText-root {
          .MuiTypography-root {
            color: #2b3674;
            font-weight: 500;
            font-size: 12px;
            max-width: 91%;
          }
        }
        .MuiListItem-root {
          padding: 0px;
        }
        .MuiSwitch-root {
          width: 28px;
          height: 18px;
          padding: 0;
          margin: 8px;
          .MuiSwitch-switchBase {
            padding: 0;
            margin: 2px;
            transition-duration: 300ms;
            .MuiSwitch-thumb {
              box-sizing: border-box;
              width: 14.2px;
              height: 14.2px;
            }
          }
          .MuiSwitch-track {
            border: 0;
            border-radius: 13px;
            opacity: 1;
            -webkit-transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          }
          &.active {
            .MuiSwitch-thumb {
              width: 15px;
            }
            .MuiSwitch-switchBase.Mui-checked {
              transform: translateX(9px);
            }
          }
        }
        .MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
          color: #fff;
          margin-left: -7px;
        }
      }
    }
    .MuiPaper-root:before {
      display: none;
    }
    .MuiAccordionSummary-root {
      min-height: unset;
    }
    .MuiAccordionSummary-content {
      font-weight: 700;
      font-size: 16px;
      color: #2b3674;
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 16px 0px;
    }
    .MuiFormControlLabel-root {
      width: 100%;
    }
  }
}

.filter_container {
  z-index: 100;
  position: absolute;
  margin-top: 49px;
  margin-right: -16px;

  .filterBar {
    position: relative;
  }

  @media (max-width: $breakpoint-ms) {
    position: relative;
    margin-top: 0;
    margin-right: 0;
    .filterBar{
      margin: 0;
      border-radius: 0;
      box-shadow: inherit;
      max-width: 100%;
      width: 100%;
      height: inherit;
      max-height: inherit;
    }
  }

}
