/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

$peLinearGradient: linear-gradient(90deg, #002318 0%, #003927 38.54%, rgba(0, 104, 72, 0) 100%);
$poLinearGradient: linear-gradient(90deg, #002318 0%, #003927 38.54%, rgba(0, 104, 72, 0) 100%);
$cmeLinearGradient: linear-gradient(90deg, rgba(0, 13, 25, 1) 0%, rgba(0, 28, 55, 1) 38%, rgba(0, 54, 106, 0) 100%);
$ewiseLinearGradient: linear-gradient(90deg, rgba(42, 0, 21, 1) 0%, rgba(54, 0, 27, 1) 38%, rgba(103, 0, 52, 0) 100%);

.course_introduction {
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.cmeTheme {
  .course_introduction {
    .gradient-overlay {
      background: $cmeLinearGradient;
    }
  }
}
.peTheme {
  .course_introduction {
    .gradient-overlay {
      background: $peLinearGradient;
    }
  }
}
.poTheme {
  .course_introduction {
    .gradient-overlay {
      background: $poLinearGradient;
    }
  }
}
.ewiseTheme {
  .course_introduction {
    .gradient-overlay {
      background: $ewiseLinearGradient;
    }
  }
}

.course_introduction {
  .nonTrialCourse {
    filter: grayscale(100%);
    opacity: 0.5;
    .favourite_icon {
      display: none;
    }
  }
  // TODO: ❌ Hidden for user testing
  .sidebar .accreditation-collage {
    width: 100%;
  }
  // ------------
  width: 100%;
  margin-top: 76px;
  padding-left: 16px;
  @media (max-width: $breakpoint-ms) {
    margin-top: 170px;
    padding-left: 0px;
  }
  @media (max-width: $breakpoint-sm) {
    margin-top: 170px;
  }
  .sticky_content {
    position: fixed;
    top: 0px;
    //left: 310px;
    z-index: 2;
    @media (max-width: $breakpoint-ms) {
      top: 171px;
    }
  }
  .header {
    width: 100%;
    height: 100%;
    max-height: 400px;
    position: relative;
    overflow: hidden;
    border-radius: 17.2584px;
    img {
      width: 100%;
      margin: auto;
      z-index: 0;
      object-fit: cover;
    }
    .favorites_box {
      display: flex;
      gap: 16px;
      position: absolute;
      top: 35px;
      right: 23px;
      z-index: 3;
      .like_icon {
        background: white;
        width: 50px;
        height: 50px;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
      .favourite_icon {
        background: white;
        width: 50px;
        height: 50px;
        &:hover {
          background: #fff;
          color: #000;
        }
      }
      @media (max-width: $breakpoint-sm) {
        top: 8px;
        right: 8px;
      }
    }
    .chips_box {
      display: flex;
      gap: 16px;
      position: absolute;
      top: 45px;
      left: 32px;
      z-index: 3;
      .MuiChip-root {
        width: fit-content;
        border-radius: 70px;
        color: white;
        .MuiChip-label {
          padding-right: 16px;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }

    .course_information {
      margin: 0 auto;
      padding: 32px;
      z-index: 1;
      top: 65px;
      position: absolute;
      width: 100%;
      .content {
        max-width: 66.6667%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .MuiChip-root {
          // position: absolute;
          // top: 18px;
          // left: 15px;
          width: fit-content;
          border-radius: 70px;
          background-color: white;
          .MuiChip-label {
            padding-right: 16px;
            font-weight: 700;
            font-size: 14px;
          }
        }
        .authors-wrapper {
          margin-bottom: 8px;
          color: #fff;
        }
        .status {
          background: #ffffff;
          border: 1px solid #eeeeee;
          border-radius: 20px;
          width: 60%;
          display: flex;
          padding: 0px 15px;
          height: 82px;
          .MuiLinearProgress-root {
            height: 10px;
            border-radius: 42px;
          }
          .linear_label {
            display: none;
          }
        }
        .course_accreditations {
          display: flex;
          margin-bottom: 16px;
          .course_overview_card_accreditations {
            display: flex;
          }
          .MuiAvatar-root {
            margin-left: 2.5px;
            margin-right: 2.5px;
            width: 33px;
            height: 33px;
            font-size: 14px;
            // background: #f4f7fe;
            &.chip {
              background: #f4f7fe;
              border: 1.34146px solid rgba(0, 104, 72, 0.01);
              box-shadow: 0px 12.0732px 53.6585px rgba(112, 144, 176, 0.12);
              border-radius: 13.4146px;
            }
          }
        }
      }
      @media (max-width: $breakpoint-ipad) {
        top: 60px;
        .content {
          .status {
            width: 90%;
            .css-h32vmu-MuiTypography-root {
              font-size: 15px;
            }
            .css-1wdi87c-MuiTypography-root {
              font-size: 10px;
            }
          }
        }
      }
      @media (max-width: 380px) {
        h1 {
          font-size: 28px;
        }
        .content {
          grid-gap: 0;
          gap: 0;
          height: 100%;
          justify-content: space-between;
        }
      }
      @media (max-width: $breakpoint-sm) {
        top: 0px;
        padding: 7px;
        height: 100%;
        .content {
          .status {
            width: 100%;
            padding: 0px 10px;
            height: 80px;
            .MuiGrid-root {
              flex-direction: row;
              gap: 1px;
              .MuiGrid-item {
                margin-bottom: 0px;
                margin-top: 0px;
                font-size: 10px;
              }
              div {
                font-size: 10px;
              }
            }
            p {
              font-size: 15px;
            }
          }
        }
        h1 {
          line-height: normal;
          font-size: 20px;
        }
        p {
          font-size: 17px;
        }
        .content {
          max-width: 100%;
        }
      }
    }
  }

  .full_content {
    #content_tabs.scroll .MuiTabs-root {
      max-width: inherit !important;
    }
    .full_container_drawer {
      flex-direction: column-reverse;
      .MuiGrid-root {
        max-width: 100%;
      }
      #content_tabs.scroll .MuiTabs-root {
        max-width: inherit !important;
      }

      #content_tabs {
        border: 1px solid #eeeeee;
        border-radius: 20px;
        background: #ffffff;
        padding: 10px 13px;
        .MuiButtonBase-root {
          border-radius: 15px;
          padding: 10px 28px;
          text-transform: capitalize;
          min-height: 41px;
        }
        .tab_label {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
        }
        .MuiTabs-flexContainer {
          gap: 10px;
        }
        .MuiTabs-root {
          .MuiTabs-indicator {
            display: none;
          }
          .MuiButtonBase-root {
            //background: rgba(0, 104, 72, 0.05);
            // box-shadow: 0px 20px 40px rgba(112, 144, 176, 0.12);
            color: #2b3674;
            border-radius: 20px;
            text-transform: capitalize;
          }
          .MuiButtonBase-root.active-scroll-spy {
            color: white;
            .tab_label {
              img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(60deg) brightness(123%) contrast(103%);
              }
            }
          }
        }
      }

      .content {
        .the_content {
          margin-top: 32px;
          margin-bottom: 32px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media (max-width: $breakpoint-sm) {
            margin-bottom: 16px;
            margin-top: 16px;
            gap: 16px;
          }
          > div:first-of-type {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
          h1 {
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          p {
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          h2 {
            font-size: 22px;
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          h6 {
            font-size: 16px;
          }
          .course_description {
            font-size: 18px;
            line-height: 34px;
            li {
              list-style: inside;
            }
            @media (max-width: $breakpoint-sm) {
              ul,
              ol {
                padding: 0px;
              }
            }
          }
          .course_podcast {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            audio {
              width: 100%;
            }
            div {
              .podcast-container {
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                color: rgba(0, 0, 0, 0.87);
                header {
                  display: none;
                }
                figure {
                  margin: 0px;
                }
              }
            }

            audio::-webkit-media-controls-panel,
            video::-webkit-media-controls-panel {
              background-color: white;
            }
            audio::-webkit-media-controls-panel {
              padding: 0px;
            }
            .podcast_holder {
              position: relative;
              .hide_title {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 29px;
                background: #f4f4f6;
              }
            }
          }
          .course_preview {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            height: auto;
            display: flex;
            flex-direction: column;
          }
          .course_goals {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            //color: #2b3674;
            font-size: 18px;
            line-height: 34px;
            div {
              font-size: 18px;
              line-height: 34px;
            }
            p {
              font-size: 18px;
              line-height: 34px;
            }
            span {
              font-size: 18px;
              line-height: 34px;
            }
            ul {
              list-style: inside;
              padding-inline-start: 40px;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 600;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
              line-height: 1.167;
              letter-spacing: -0.01562em;
              color: #2b3674;
              font-size: 24px;
            }
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-size: 18px;
            }
          }
          .course_overview_quickview_authors {
            p {
              font-size: 1.1em;
              line-height: 1.3em;
            }
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            display: flex;
            flex-direction: column;
            gap: 9px;
          }
        }
      }
      .sidebar_container {
        .MuiPaper-root.MuiCard-root {
          background: #f5f5f5;
          border-radius: 17.2584px;
          border: 1px solid #eeeeee;
          padding: 13px;
          box-shadow: none;
          .MuiCardActions-root {
            display: flex;
            flex-direction: column;
            gap: 19px;
          }
        }
        // position: relative;
        // height: auto;
        // width: 100%;
        // right: 0;
        // top: 0;
        .sidebar {
          margin-top: 0px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .course_lessons {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 15px;
            .lessons_display {
              display: flex;
              gap: 5px;
              padding: 13px 7px;
              position: relative;
              justify-content: flex-start;
              align-items: center;
              .lesson_button {
                margin-left: auto;
                .MuiSvgIcon-root {
                  width: 15px;
                  height: 12px;
                }
              }
            }
          }
          .start_button {
            border-radius: 15px;
            box-shadow: none;
            display: flex;
            gap: 12px;
            color: #fff;
            &:not(.Mui-disabled) {
              background-color: #e27739;
              &:hover {
                background-color: rgb(158, 83, 39);
              }
            }
          }
          // .start_button:hover {
          //   background-color: rgb(158, 83, 39);
          // }
          .course_materials {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            gap: 21px;
            .materials {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .MuiBox-root {
                border: 1px solid #eeeeee;
                border-radius: 16px;
                padding: 18px;
                width: 100%;
                box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
                font-weight: 500;
              }
            }
          }
          .endDate_alert {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 15px;
            display: flex;
            gap: 21px;
          }
          .course_details {
            h3 {
              font-weight: bold;
              margin-bottom: 8px;
              font-size: 20px;
            }
            .MuiGrid-root {
              margin: 0px;
              font-size: 16px;
              &.spec {
                margin-bottom: 8px;
              }
              &.icon {
                padding-right: 10px;
              }
            }
          }
          .course_introduction_image {
            position: relative;
            .MuiCardMedia-root {
              height: 194px;
            }
            .course_introduction_movie {
              position: absolute;
              left: calc(50% - 44px);
              top: calc(50% - 44px);
              z-index: 2;
              .MuiButtonBase-root {
                color: #fff;
                svg {
                  font-size: 4.5rem;
                }
              }
            }
          }
        }
      }
    }
    .full_container {
      @media (max-width: $breakpoint-sm) {
        flex-direction: column-reverse;
      }

      #content_tabs.sticky_content {
        border: none;
        border-radius: 0%;
        padding-top: 95px;
        width: 100%;
        @media (max-width: $breakpoint-ms) {
          overflow: hidden;
          padding-right: 36px;
          padding-left: 0px;
        }
      }
      #content_tabs.scroll .MuiTabs-root {
        max-width: inherit !important;
      }
      #content_tabs {
        border: 1px solid #eeeeee;
        border-radius: 20px;
        background: #ffffff;
        padding: 10px 13px;
        @media (min-width: 1620px) {
          width: fit-content;
        }
        .MuiButtonBase-root {
          border-radius: 15px;
          padding: 10px 28px;
          text-transform: capitalize;
          min-height: 41px;
        }
        @media (max-width: $breakpoint-sm) {
          gap: 7px;
          .MuiButtonBase-root {
            min-height: 41px;
            font-size: 14px;
            padding: 6px 9px;
            .MuiBadge-badge {
              font-size: 7px;
              height: 12.7px;
            }
          }
        }
        @media (max-width: $breakpoint-ipad) {
          width: inherit;
        }
        .tab_label {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 15px;
          text-transform: initial;
        }
        .MuiTabs-flexContainer {
          gap: 10px;
        }
        .MuiTabs-root {
          .MuiTabs-indicator {
            display: none;
          }
          .MuiButtonBase-root {
            // box-shadow: 0px 20px 40px rgba(112, 144, 176, 0.12);
            color: #2b3674;
            border-radius: 20px;
            text-transform: capitalize;
          }
          .MuiButtonBase-root.active-scroll-spy {
            color: white;
            .tab_label {
              img {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(60deg) brightness(123%) contrast(103%);
              }
            }
          }
        }
      }

      .content {
        .the_content {
          margin-top: 32px;
          margin-bottom: 32px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          @media (max-width: $breakpoint-sm) {
            margin-bottom: 16px;
            margin-top: 16px;
            gap: 16px;
          }
          > div:first-of-type {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .MuiBox-root {
              scroll-margin-top: 13rem;
              @media (max-width: $breakpoint-ms) {
                scroll-margin-top: 24rem;
              }
            }
          }
          h1 {
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          p {
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          h2 {
            font-size: 22px;
            margin-bottom: 16px;
            @media (max-width: $breakpoint-sm) {
              margin-bottom: 8px;
            }
          }
          h6 {
            font-size: 16px;
          }

          .course_description {
            font-size: 18px;
            line-height: 34px;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

            li {
              list-style: inside;
            }
            span {
              font-size: 18px !important;
              line-height: 34px;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            }
            @media (max-width: $breakpoint-sm) {
              ul,
              ol {
                padding: 0px;
              }
            }
          }
          .course_podcast {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            font-size: 1.1em;
            line-height: 34px;
            letter-spacing: 0.00938em;
            color: rgba(0, 0, 0, 0.87);
            audio {
              width: 100%;
            }
            div {
              .podcast-container {
                font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
                font-weight: 400;
                header {
                  display: none;
                }
                figure {
                  margin: 0px;
                }
              }
            }

            audio::-webkit-media-controls-panel,
            video::-webkit-media-controls-panel {
              background-color: white;
            }
            audio::-webkit-media-controls-panel {
              padding: 0px;
            }
            .podcast_holder {
              position: relative;
              .hide_title {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 29px;
                background: #f4f4f6;
              }
            }
          }
          .course_preview {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            height: auto;
            display: flex;
            flex-direction: column;
            .video-wrapper {
              position: relative;
              padding-top: 56.25%;
              border-radius: 6px;
              overflow: hidden;
              .video-player {
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
          .course_goals {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            //color: #2b3674;
            font-size: 18px;
            line-height: 34px;
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
            ul {
              list-style: inside;
              padding-inline-start: 40px;
              @media (max-width: $breakpoint-sm) {
                padding-inline-start: 18px;
              }
            }
            p {
              font-size: 18px;
              line-height: 34px;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
            }
            span {
              font-size: 18px !important;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
            }
            div {
              font-size: 18px;
              line-height: 34px;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-weight: 600;
              font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
              line-height: 1.167;
              letter-spacing: -0.01562em;
              color: #2b3674;
              font-size: 24px;
            }
            h2,
            h3,
            h4,
            h5,
            h6 {
              font-size: 18px;
            }
          }
          .course_overview_quickview_authors {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 18px;
            display: flex;
            flex-direction: column;
            gap: 17px;
            p {
              font-size: 1em;
              line-height: 1.9em;
            }
          }
        }
      }
      .sidebar_container {
        align-self: flex-start;
        position: absolute;
        top: 275px;
        right: 32px;
        width: 20%;
        @media (max-width: 900px) {
          top: 36%;
          bottom: 0;
          width: 34%;
        }
        @media (max-width: 768px) {
          width: 34%;
          right: 32px;
          top: 479px;
        }
        @media (width: $breakpoint-ipad) {
          width: 25%;
          right: 31px;
        }
        .MuiPaper-root.MuiCard-root {
          background: #f5f5f5;
          border-radius: 17.2584px;
          border: 1px solid #eeeeee;
          padding: 13px;
          box-shadow: none;
          .MuiCardActions-root {
            display: flex;
            flex-direction: column;
            gap: 19px;
          }
        }
        .sidebar {
          //margin-top: -180px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          .course_lessons {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 15px;
            .lessons_display {
              display: flex;
              gap: 5px;
              padding: 13px 7px;
              position: relative;
              justify-content: flex-start;
              align-items: center;
              .lesson_button {
                margin-left: auto;
                .MuiSvgIcon-root {
                  width: 15px;
                  height: 12px;
                }
              }
            }
          }
          .start_button {
            border-radius: 15px;
            box-shadow: none;
            display: flex;
            gap: 12px;
            color: #fff;
            &:not(.Mui-disabled) {
              background-color: #e27739;
              &:hover {
                background-color: rgb(158, 83, 39);
              }
            }
            // background-color: #e27739;
          }
          // .start_button:hover {
          //   background-color: rgb(158, 83, 39);
          // }
          .course_materials {
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 20px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            gap: 21px;
            .materials {
              display: flex;
              flex-direction: column;
              gap: 10px;
              .MuiBox-root {
                border: 1px solid #eeeeee;
                border-radius: 16px;
                padding: 18px;
                width: 100%;
                box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
                font-weight: 500;
              }
            }
          }
          .endDate_alert {
            border: 1px solid #eeeeee;
            border-radius: 20px;
          }
          .course_details {
            h3 {
              font-weight: bold;
              margin-bottom: 8px;
              font-size: 20px;
            }
            .MuiGrid-root {
              margin: 0px;
              font-size: 16px;
              &.spec {
                margin-bottom: 8px;
              }
              &.icon {
                padding-right: 10px;
              }
            }
          }
          .course_introduction_image {
            position: relative;
            .MuiCardMedia-root {
              height: 194px;
            }
            .course_introduction_movie {
              position: absolute;
              left: calc(50% - 44px);
              top: calc(50% - 44px);
              z-index: 2;
              .MuiButtonBase-root {
                color: #fff;
                svg {
                  font-size: 4.5rem;
                }
              }
            }
          }
        }
        @media (max-width: $breakpoint-sm) {
          position: relative;
          height: auto;
          width: 100%;
          right: 0;
          top: 0;
          .sidebar {
            margin-top: 0px;
          }
        }
      }
      #content_tabs {
        &.scroll {
          position: fixed;
          left: 0px;
          width: 100%;
          background: #fff;
          z-index: 9;
          right: 0;
          .MuiTabs-root {
            margin: 0 auto;
            padding-left: 24px;
            padding-right: 24px;
          }
        }
      }
    }
  }
}

.course_introduction_video {
  margin: 0px;
  padding: 0px;
  .MuiDialog-container {
    .MuiPaper-root {
      min-width: 640px;
      min-height: 360px;
      overflow: visible;
      justify-content: center;
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .course_overview_quickview_buttons {
      position: absolute;
      right: 0;
      top: -3em;
      z-index: 99;
      @media (max-width: $breakpoint-ms) {
        right: 16px;
        top: 16px;
      }
      .MuiButtonBase-root {
        .MuiSvgIcon-root {
          fill: #fff;
          width: 1.5em;
          height: 1.5em;
          @media (max-width: $breakpoint-ms) {
            fill: #000;
          }
        }
      }
    }
    .video_loader {
      margin: 0 auto;
      text-align: center;
    }
  }
}

.course_related {
  .carousel-item {
    .card__wrapper {
      .badge_course_status {
        line-height: 31px;
        height: auto;
        color: white;
        box-shadow: 1px 2px 15px #0000005e;
        width: 100%;
        .MuiChip-label {
          padding: 0px 8px !important;
        }
      }
      .card__authors {
        .authors-wrapper {
          display: flex;
          flex-flow: wrap;
          gap: 1%;
          flex: 1 1 auto;
          .chip_author {
            top: 0px !important;
            left: 0px !important;
            padding: 0px;
            margin: 0px;
            margin-top: 2px;
            height: auto;
            position: relative !important;
            border: none !important;
            border-radius: 5px;
            background: #f8f8f8;
            // background: #26899E;
            color: #2c3331;
            .MuiChip-label {
              padding: 0px 6px !important;
              font-size: 13px !important;
              font-weight: 400 !important;
            }
          }
        }
      }
      .card__categories {
        position: absolute;
        bottom: 12px;
        left: 0px;
        padding: 0px 16px;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        grid-gap: 1%;
        gap: 1%;
        flex: 1 1 auto;
        .chip_category {
          top: 0px !important;
          left: 0px !important;
          padding: 0px 4px;
          margin: 0px;
          margin-top: 3px;
          height: auto;
          position: relative !important;
          border-radius: 9px !important;
          box-shadow: 0px 0px 12px #00000066;
          // TODO: change border color to theme color
          border: 1px solid #2a4a92;
          background: #ffffffd6 !important;
          // background: #FFBD60 !important;
          .MuiChip-label {
            padding: 0px 4px !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            font-style: italic;
            // TODO: change font color to theme color
            color: #2a4a92;
            // color: #3F51B5;
          }
        }
      }
      .card__image {
        display: flex;
        // height: auto;
        img {
          height: auto;
        }
      }

      .card__content {
        transition: all 0.2s;
        display: flex;
        flex-direction: column;
        .card__title {
          padding: 16px;
          display: flex;
          margin-bottom: auto;
          .course_title {
            .course_title_main {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              white-space: normal;
            }
            .course_title_sub {
              font-size: 0.8rem;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: auto;
            }
          }
        }
        .card__authors {
          padding: 0px 16px;
          display: flex;
          margin-top: auto;
        }
        .card__symbols_points {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          .card__symbols {
            display: flex;
            align-items: center;
            justify-content: center;
            .course_overview_card_accreditations {
              display: flex;
              flex-flow: wrap-reverse;
              gap: 3px;
            }
          }
          .card__points {
            display: flex;
            align-items: flex-end;
            height: 100%;
            gap: 6px;
            .podcast_wrapper {
              display: flex;
              align-items: center;
              height: 100%;
              .podcast_icon {
                padding: 6px;
                .MuiSvgIcon-root {
                  width: 15px;
                  height: 15px;
                }
              }
            }
            .duration_icon {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
.header_mobile {
  .course_preview {
    background: #ffffff;
    height: 56.25vw;
    display: flex;
    flex-direction: column;
    margin: -18px;
    margin-bottom: 18px;
    iframe {
      height: 56.25vw;
    }
  }
  .favorites_box {
    text-align: center;
    .MuiIconButton-root {
      background: #f5f7fe;
    }
  }
  .sidebar {
    .MuiCard-root {
      transition: inherit;
      box-shadow: inherit;
    }
    .MuiCardContent-root {
      padding: 0;
      .MuiGrid-root {
        margin: 0;
        margin-bottom: 8px;
      }
    }
    .MuiCardActions-root {
      padding: 0;
    }
  }
  .course_materials {
    margin-top: 16px;
    background: #f4f7fe;
    padding: 16px;
  }
  .course_accreditations {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
    .course_overview_card_accreditations {
      display: flex;
    }
    .MuiAvatar-root {
      margin-left: 2.5px;
      margin-right: 2.5px;
      width: 33px;
      height: 33px;
      font-size: 14px;
      // background: #f4f7fe;
      &.chip {
        background: #f4f7fe;
        border: 1.34146px solid rgba(0, 104, 72, 0.01);
        box-shadow: 0px 12.0732px 53.6585px rgba(112, 144, 176, 0.12);
        border-radius: 13.4146px;
      }
    }
  }
}
