/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

.stack_toast {
  .bottom_0.MuiSnackbar-root {
    //bottom: 16px;
    top: unset;
  }
  .MuiSnackbar-root.MuiSnackbar-anchorOriginTopCenter {
    top: 71px;
  }
  .MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
    top: 71px;
  }
  .MuiSnackbar-root.MuiSnackbar-anchorOriginTopLeft {
    top: 71px;
  }

  .MuiSnackbar-root:nth-child(2n) {
    top: 107px;
  }
  .MuiSnackbar-root:nth-child(3n) {
    top: 159px;
  }
  .MuiSnackbar-root {
    width: 17%;
  }
  .MuiPaper-root {
    width: 100%;
    color: white;
    .MuiAlert-message {
      width: 100%;
    }
  }
  .MuiPaper-root.snackbar-success {
    background-color: #2e7d32;
  }
  .MuiPaper-root.snackbar-info {
    background-color: #0288d1;
  }
  .MuiPaper-root.snackbar-warning {
    background-color: #ed6c02;
  }
  .MuiPaper-root.snackbar-error {
    background-color: #d32f2f;
  }
  @media (max-width: $breakpoint-ms) {
    .MuiSnackbar-root {
      width: 50%;
    }
  }
  @media (max-width: $breakpoint-sm) {
    .MuiSnackbar-root {
      width: 75%;
      left: 12.5%;
      right: 12.5%;
    }
  }
}
