
@layer base {
    :root {
        --color-primary: #006848;
        --color-primary-light: #0068480D;
    }
    .ewiseTheme {
        --color-primary: #760F4E;
        --color-primary-light: #760F4E0D;
    }
    .cmeTheme {
        --color-primary: #2A4A92;
        --color-primary-light: #2A4A920D;
    }
    .poTheme {
        --color-primary: #006848;
        --color-primary-light: #0068480D;
    }
}