.info_card__container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  .info_stack__container {
    display: flex;
    flex-flow: wrap;
    gap: 5px;

    &>div {
      margin: 0px;
      display: flex;
      flex-flow: wrap;
      flex: 1 1 auto;
      justify-content: center;
    }
  }
}
.draft-view {
  .draft-card {
    &:hover {
      transition: box-shadow .5s;
      box-shadow: 0 0 20px #00000040;
      cursor: pointer;
      .image-header:after { z-index:1; }
      .edit { z-index: 2; }
      img { transform: scale(1.1); transition: all .5s;}
    }
    .image-header {
      overflow: hidden;
      border-radius: 20px;
      img {transition: all .5s}
      &:after {
        content: "";
        background: #00000054;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: block;
        position: absolute;
        z-index: -1;
        // transition: z-index 1s;
      }
    }

    .edit {
      background-color: white;
      display: block;
      border-radius: 50%;
    /*   top: 15px; */
      color: green;
    /*   left: 15px; */
      height: 60px;
      width: 60px;
      line-height: 60px;
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      text-align: center;
      z-index: -1;
      // transition: z-index 1s;
    }
  }

}
