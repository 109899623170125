/* Define our breakpoints */
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-ms: 900px;
$breakpoint-ipad: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1536px;

$card_borderRadius: 15px;
$card_hoverShadow: 0 0 30px rgba(0, 0, 0, 0.25);
$card_transitionBoxShadow: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

.course_overview_list {
  // TODO: clean this ugly override
  padding: 16px;
  .MuiGrid-root > .MuiGrid-item {
    padding-top: 0px;
  }

  .course_overview_card {
    display: flex;
    .card__wrapper {
      box-shadow: none;
      border-radius: $card_borderRadius;
      transition: $card_transitionBoxShadow;
      display: flex;
      flex: 1;
      position: relative;
      &:hover {
        cursor: pointer;
        transition: $card_transitionBoxShadow;
        box-shadow: $card_hoverShadow;
      }
      &.hidden {
        .hideCourse {
          transition: width 0.2s;
          transform: translateX(40px);
          width: 0px;
          button {
            border-radius: 0px;
            height: 100%;
          }
        }
        &:hover {
          .hideCourse {
            transition: width 0.2s;

            transform: translateX(0px);
            width: 40px;
          }
        }
      }

      .card__container {
        margin: 0px;
        .course_overview_card_image {
          margin: 0px;
          overflow: hidden;

          .card_image_wrapper {
            display: flex;
            flex: 1;
            background: white;
            .MuiCardMedia-root {
              position: absolute;
              height: 100%;
              width: 100%;
            }
            .badge_course_status {
              position: absolute;
              bottom: 0;
              border-radius: 0;
              line-height: 31px;
              height: auto;
              color: white;
              width: 100%;
            }
            .favourite_icon {
              position: absolute;
              top: 8px;
              right: 10px;
              background-color: white;
              border-radius: 70px;
              padding: 6px;
              .MuiSvgIcon-root {
                height: 15px;
                width: 15px;
              }
            }
            .like_icon {
              position: absolute;
              top: 8px;
              right: 44px;
              background-color: white;
              border-radius: 70px;
              padding: 6px;
              .MuiSvgIcon-root {
                height: 15px;
                width: 15px;
              }
            }
          }
        }
        .course_overview_card_info {
          margin: 0px;
          .card_info_wrapper {
            padding: 16px;
            display: flex;
            flex: 1;
            flex-direction: column;
            .card__title {
              .course_title_main {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: auto;
              }
              .course_title_sub {
                font-size: 12px;
              }
            }

            .card__authors {
              padding-top: 10px;
              display: flex;
              margin-top: auto;
            }
            .card__categories {
              margin-top: auto;
              padding-top: 10px;
              flex: 0;
              position: absolute;
              bottom: 12px;
              .chip_category {
                box-shadow: none;
                padding: 0;
                .MuiChip-label {
                  background-color: white;
                  border-radius: 9px !important;
                  font-size: 14px !important;
                  padding: 0px 8px !important;
                }
              }
            }
            .card__description {
              div {
                overflow: hidden;
                height: 75px;
                line-height: 20px;
                margin-inline-start: 0px !important;
                margin-inline-end: 0px !important;
                img {
                  display: none;
                }
              }
              div > :first-child {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              div :not(:first-child) {
                display: none;
              }
              span {
                font-size: 14px;
                line-height: 20px;
              }
              p {
                margin-block-start: 1em !important;
                margin-block-end: 1em !important;
                margin-inline-start: 0px !important;
                margin-inline-end: 0px !important;
              }
            }
            .card__symbols__points {
              display: flex;
              padding-top: 10px;
              justify-content: space-between;
              // .card__symbols {}
              .card__points {
                display: flex;
                gap: 3px;
                .podcast_wrapper {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  .podcast_icon {
                    .MuiSvgIcon-root {
                      width: 15px;
                      height: 15px;
                    }
                  }
                }
              }
              .course_overview_card_accreditations {
                display: flex;
                flex-flow: wrap-reverse;
                grid-gap: 3px;
                gap: 3px;
                .MuiAvatar-root {
                  margin-left: 2.5px;
                  margin-right: 2.5px;
                  width: 30px;
                  height: 30px;
                  font-size: 14px;
                }
              }
              .duration_icon {
                white-space: nowrap;
                color: #fff;
                padding: 5px 16px;
                font-weight: 500;
                font-size: 14px;
                height: 34px;
                border-radius: 70px;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
  .courseview_category_introduction {
    margin-bottom: 16px;
  }
}
.course_overview_list2 {
  margin-right: 16px;
  h1 {
    font-size: 18px;
  }
  .MuiGrid-root {
    width: inherit;
    .MuiBox-root {
      overflow: hidden;
    }
    margin: 0px;
    height: inherit;
    .course_overview_card {
      height: 212.34px;
    }
    &.course_overview_card_image {
      width: 25%;
      .done_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        line-height: 70px;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        text-align: center;
        z-index: 10;
        .MuiSvgIcon-root {
          width: 41px;
          height: 37px;
          color: white;
        }
      }
    }
    &.course_overview_card_info {
      width: 75%;
      padding: 17px;
      position: relative;
    }
    &.course_overview_card_image {
      height: inherit;
    }
    &.course_overview_card_image,
    &.course_overview_card_info {
      @media (max-width: $breakpoint-ms) {
        width: 50%;
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }
  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
  .MuiCard-root {
    box-shadow: none;
    cursor: pointer;
    height: 100%;
    border-radius: 15px;

    .MuiCardContent-root {
      display: flex;
      padding: 0px;
      height: 80%;
    }

    img {
      transition: ease all 0.75s;
      height: inherit;
    }
    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
      img {
        transform: scale(110%);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
      }
    }
    .MuiChip-root {
      position: absolute;
      top: 18px;
      left: 15px;
      border-radius: 70px;
      background-color: white;
      .MuiChip-label {
        padding-right: 16px;
        font-size: 14px;
      }
    }
    .course_description {
      div {
        overflow: hidden;
        height: 75px;
        line-height: 20px;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
        img {
          display: none;
        }
      }
      div > :first-child {
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      div :not(:first-child) {
        display: none;
      }
      span {
        font-size: 14px;
        line-height: 20px;
      }
      p {
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
      }
    }

    .favourite_icon {
      position: absolute;
      top: 18px;
      right: 15px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .like_icon {
      position: absolute;
      top: 18px;
      right: 52px;
      background-color: white;
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .podcast_icon {
      border-radius: 70px;
      padding: 6px;
      .MuiSvgIcon-root {
        height: 15px;
        width: 15px;
      }
    }
    .duration_icon {
      color: #fff;
      padding: 5px 16px;
      font-weight: 500;
      font-size: 14px;
      height: 25px;
      border-radius: 70px;
      display: flex;
      align-items: center;
    }
    .MuiCardActions-root {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 20%;
      .podcast_icon {
        border-radius: 70px;
        padding: 6px;
        .MuiSvgIcon-root {
          height: 15px;
          width: 15px;
        }
      }
    }
    .course_overview_card_accreditations {
      display: flex;
      .MuiAvatar-root {
        margin-left: 2.5px;
        margin-right: 2.5px;
        width: 30px;
        height: 30px;
        font-size: 14px;
        border: 1px solid;
        &.chip {
          border: none;
        }
      }
    }
    .MuiButtonBase-root {
      font-size: 15px;
    }
    &.open {
      .MuiChip-root {
        background: rgba(140, 120, 100, 0.5);
      }
    }
    &.comingsoon {
      .MuiChip-root {
        background: rgba(45, 159, 255, 0.7);
      }
    }
    &.due {
      .MuiChip-root {
        background: rgba(255, 45, 45, 0.7);
      }
    }
  }
  .hidden.MuiCard-root {
    &:hover {
      .MuiCardContent-root {
        padding-top: 9px;
      }
      .course_description {
        div > :first-child {
          display: -webkit-box;
          max-width: 100%;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .hideCourse {
        width: 100%;
        height: 34px;
        left: 0;
        .test {
          bottom: 0px;
          transform: translate3d(0, 0, 0);
          transition: bottom 0.2s;
          opacity: 1;
        }
      }
    }
  }
}
